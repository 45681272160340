const FONT_ENUMS = [
  {
      "locale_code": "am",
      "font": "NotoSerifEthiopic-Regular"
  },
  {
      "locale_code": "ar",
      "font": "IBMPlexSansArabic-Regular"
  },
  {
      "locale_code": "bn",
      "font": "HindSiliguri-Regular"
  },
  {
      "locale_code": "fa",
      "font": "IBMPlexSansArabic-Regular"
  },
  {
      "locale_code": "hy",
      "font": "NotoSerifArmenian-Regular"
  },
  {
      "locale_code": "ja",
      "font": "ZenKakuGothicNew-Regular",
  },
  {
      "locale_code": "kar",
      "font": "NotoSansKayahLi-Regular"
  },
  {
      "locale_code": "km",
      "font": "Battambang-Regular"
  },
  {
      "locale_code": "ko",
      "font": "IBMPlexSansKR-Regular"
  },
  {
      "locale_code": "ksw",
      "font": "Padauk-Regular"
  },
  {
      "locale_code": "lo",
      "font": "NotoSansLaoLooped-Regular"
  },
  {
      "locale_code": "my",
      "font": "Padauk-Regular"
  },
  {
      "locale_code": "pa",
      "font": "TiroGurmukhi-Regular"
  },
  {
      "locale_code": "prs",
      "font": "IBMPlexSansArabic-Regular"
  },
  {
      "locale_code": "pbt",
      "font": "IBMPlexSansArabic-Regular"
  },
  {
      "locale_code": "pbu",
      "font": "IBMPlexSansArabic-Regular"
  },
  {
      "locale_code": "ru",
      "font": "Roboto-Light",
  },
  {
      "locale_code": "uk",
      "font": "Roboto-Light",
  },
  {
      "locale_code": "zh",
      "font": "ZCOOLXiaoWei-Regular",

  },
  {
      "locale_code": "ur",
      "font": "IBMPlexSansArabic-Regular"
  },
  {
      "locale_code": "haw",
      "font": "Amiri-Regular"
  },
  {
      "locale_code": "to",
      "font": "Amiri-Regular"
  },
];

export default FONT_ENUMS;
