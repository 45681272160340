import { get } from 'lodash';
import { useState, useEffect } from 'react';
import {
  fetchAssistanceRequestConfigs, fetchCoreToken, fetchConsentAgreement, fetchServiceTypes,
} from '../actions';

/*
  initialCoreTokenData = {
    api_key: "KiTYKSRBaKA16buHE5NKvT6Wpfl-t9cnVvrzS9mx",
    provider_id: "229b8b89-7e61-4551-bc3e-5f41fcc76151",
    token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjEzMnIyMjk4ZmgifQ.eyJpc3MiOm51bGwsInN1YiI6IjRkMGUxOTRmLWJiNjItNGZjNy04MDIyLTA1ZGFlZTkzM2VlYSIsImVtYWlsIjoiZ2xvYmFsLWFyLWFwaS1rZXlAdW5pdGV1cy5jb20iLCJhdWQiOltdLCJleHAiOjM4MDUwNTMwNDAsIm5iZiI6MTY1NzU2OTM5MywiaWF0IjoxNjU3NTY5MzkzLCJqdGkiOiJiZDg1NzBlZjMxMWEyNThjOTFmODNmM2FhMDBkOTA0ODY1NDhkZjZkNzBlNjc1ODgxYiIsInVzZXJfaW50ZXJjb21faG1hYyI6ImNiNGNlYTZlMjJmMDEwYzM1NzkzZjFkM2I5Mzc1ZDhkZjAzODk1YzU0ZTFlNzRmNjY1ZTkzNmJiZGEzNzY1NmEifQ.ZTWrXs2BcSAR3MoQksTM_OnzScjcFLk8-7n4fvPQ-54"
  }
*/
const useAssistanceRequest = (legacyToken, initialCoreTokenData, language) => {
  const [state, setState] = useState({
    legacyToken,
    coreTokenData: null,
    consentAgreement: null,
    assistanceReqConfigs: null,
    customForm: null,
    serviceTypes: null,
    language: language
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const initialCoreTokenProvider = get(initialCoreTokenData, 'provider_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const coreTokenData = initialCoreTokenData || await fetchCoreToken(legacyToken);
        const consentAgreement = await fetchConsentAgreement(coreTokenData, state.language);
        const { assistanceReqConfigs, customForm } = await fetchAssistanceRequestConfigs(coreTokenData, state.language);
        const serviceTypes = await fetchServiceTypes(coreTokenData, state.language);

        setState({
          legacyToken,
          coreTokenData,
          consentAgreement,
          assistanceReqConfigs,
          customForm,
          serviceTypes,
          language,
        });
        setError(false)
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError('Error:', error);
      }
    };

    fetchData();
  }, [legacyToken, initialCoreTokenProvider, language]);

  return {
    error,
    isLoading,
    state,
    setState,
  };
};

export default useAssistanceRequest;
