import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { CustomSelectField, CustomTextField } from '../../components';
import { FormattedMessage } from 'react-intl';

function AssistanceRequestForm({ serviceTypes, language }) {
  const serviceTypesOptions = _.filter(serviceTypes, (s) => s && _.isEmpty(s.relationships))
    .map((serviceType) => ({ display_name: serviceType.attributes.name, value: serviceType.id }));

  return (
    <div>
      <CustomSelectField
        name="service_types"
        id="uu_service_types"
        label={
          <FormattedMessage id="9pSUzk" description="servicesSeeking" defaultMessage="What services are you seeking?"/>
        }
        options={serviceTypesOptions}
        required
        language={language}
      />
      <CustomTextField
        name="description"
        id="uu_description"
        label={
          <FormattedMessage id="ypc766" description="describeRequest" defaultMessage="Please describe your request for services"/>
        }
        required
        language={language}
      />
    </div>
  );
}

AssistanceRequestForm.propTypes = {
  serviceTypes: PropTypes.array.isRequired,
};

export default AssistanceRequestForm;
