import axios from 'axios';
import _ from 'lodash';
import { CORE_API } from '../env.config.js';

const fetchAssistanceRequestConfigs = async (coreTokenObject, language) => {
  const { token, provider_id } = coreTokenObject;
  const baseURL = CORE_API;
  const coreConfig = {
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Application-Source': 'widgets',
    },
  };

  const assistanceReqConfigs = await axios.get(
    `assistance_request_configs?filter%5Bprovider%5D=${provider_id}`,
    coreConfig,
  );

  const formId = _.get(assistanceReqConfigs,'data.data[0].relationships.form.data.id', null);
  const [provider, customForm] = await Promise.all([
    axios.get(`providers/${provider_id}`, coreConfig),
    formId ? axios.get(`forms/${formId}`, {
      ...coreConfig,
      params: {
        locale: language,
      },
    }) : null,
  ]);

  return { assistanceReqConfigs, provider, customForm };
};

export default fetchAssistanceRequestConfigs;
