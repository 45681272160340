import React from 'react';
import PropTypes from 'prop-types';
import {
  Expandable,
  Icon,
} from '@unite-us/ui';
import SurveyJSFormRenderer from '@unite-us/surveyjs/dist/components/Renderer/SurveyJSFormRenderer';
import { get } from 'lodash';

export default function SurveyJSForm(props) {
  const {
    form,
    hasErrors,
    expanded,
    handleExpandChange,
    formRef,
  } = props;

  const handleSetSurvey = (survey) => {
    formRef(survey);
  };

  const surveyJSON = {
    configuration: {
      focusFirstQuestionAutomatic: false,
      ...get(form, 'configuration', ''),
    },
  };

  return (
    <SurveyJSFormRenderer
      formData={surveyJSON}
      handleSetSurveyModel={handleSetSurvey}
      editMode
    />
  );
}

SurveyJSForm.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    configuration: PropTypes.shape,
  }).isRequired,
  hasErrors: PropTypes.bool,
  expanded: PropTypes.bool,
  handleExpandChange: PropTypes.func.isRequired,
  formRef: PropTypes.func.isRequired,
};

SurveyJSForm.defaultProps = {
  hasErrors: false,
  expanded: false,
};
