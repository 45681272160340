import { Field } from 'react-final-form';
import { TextField } from '@unite-us/ui';
import formUtils from '../utils/formUtils';

function CustomTextField({
  name, label, id, required, ...rest
}) {
  return (
    <Field
      name={name}
      validate={required ? formUtils.required(rest.language) : null}
    >
      {(params, index) => (
        <TextField
          id={id}
          label={label}
          {...params}
          required={required}
        />
      )}
    </Field>
  );
}

export default CustomTextField;
