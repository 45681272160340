import { find } from 'lodash';
import LANGUAGES from "./languageEnums";

/**
 * Get locale information from languageEnums from the lang_id provided in the url
 * IF no language is provided in the url - default to English
 * IF lang_id provided is invalid or not yet supported - default to English
 * ELSE use the locale information corresponding to language
 * 
 * This function can be reused at any point to pull all the corresponding
 * enum information since all it needs is the 2 (or 3) letter locale code
 * 
 * @param language which is a 2 or 3 letter language code from the urlParams
 * @returns locale enum object of the form
 * {
 *      display_name: language name,
 *      value: language locale code
 * }
 */

export default function getLocaleFromNavigator(language) {
    const defaultLocale = {
        "display_name": "English",
        "value": "en"
    };
    if (language === undefined) {
        return defaultLocale;
    }
    else {
        const languageCode = language.split('-')[0];
        const currentLanguagesList = LANGUAGES.supportedList;
        const searchedLocale = find(currentLanguagesList, { "value": languageCode });
        return searchedLocale === undefined ? defaultLocale : searchedLocale;
    }
}