import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

function AssistanceRequestSuccess() {
  const { state } = useLocation();

  return (
    <div className="text-center" style={{ padding: '2em' }}>
      <h2 className="text-xl font-bold">
        <FormattedMessage id="/gx5bs" description="success" defaultMessage="Success !"/>
      </h2>
      <p>
        <FormattedMessage id="Ive4js" description="thankYou" defaultMessage="Thank you for submitting your request. We are reviewing the request, and look forward to connecting you with the services you need." />
      </p>
      <p>
        <a className="font-bold" href={state?.consent}> 
          <FormattedMessage id="rNpmpO" description="downloadConsent" defaultMessage="Download Your Signed Consent Form" /> 
        </a>
      </p>
    </div>
  );
}

export default AssistanceRequestSuccess;
