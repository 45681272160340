import { Field } from 'react-final-form';
import { SelectField } from '@unite-us/ui';
import formUtils from '../utils/formUtils';

const CustomSelectField = ({ name, label, id, style, options, required, ...rest }) => (
  <Field
    name={name}
    validate={required ? formUtils.required(rest.language) : null}
  >
    {(params, index) => {
      return (
        <SelectField
          id={id}
          label={label}
          options={formUtils.formatOptions(options)}
          required={required}
          {...params}
          {...rest}
        />
      )
    }}
  </Field>
);

export default CustomSelectField;
