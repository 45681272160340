import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  CustomRadioField,
  CustomSelectField,
  CustomDateField,
} from '../../components';
import enums from '../../constants/defaultEnums';
import formUtils from '../../utils/formUtils';

function MilitaryForm({ form, assistanceReqConfigs, intl }) {
  const militaryAffiliation = form.getFieldState('military.affiliation');
  const isMilitaryMemberOrVeteran = _.get(militaryAffiliation, 'value') === 'military_member_or_veteran';
  const hasBeenDeployed = _.get(form.getFieldState('military.deployed'), 'value') === 'yes';
  const hasVAServiceDisability = _.get(form.getFieldState('military.service_connected_disability'), 'value') === 'yes';
  const hasVeteranStatusProof = _.get(form.getFieldState('military.proof_of_veteran_status'), 'value') === 'yes';

  const militaryEnums = enums.contact_data.military;

  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const collected_attributes = _.get(assistanceReqConfigs, 'data.data[0].attributes.collected_attributes');

  return (
    collected_attributes ? (
      <div>
        {collected_attributes.military_affiliation && (
        <CustomSelectField
          name="military.affiliation"
          id="uu_contact_affiliation"
          label={
            <FormattedMessage id="R6nCuZ" description="militaryAffiliation" defaultMessage="Military Affiliation"/>
          }
          options={formUtils.translateOptions(intl, militaryEnums.affiliation)}
          required
        />
        )}
        {
        isMilitaryMemberOrVeteran ? (
          <>
            <h3 className='mb-2 military ui-h3-heavy'>
              <FormattedMessage id="sw0X4x" description="militaryInformation" defaultMessage="Military Information"/>
            </h3>
            {collected_attributes.military_current_status && (
            <CustomSelectField
              name="military.current_status"
              id="uu_contact_military_current_status"
              label={
                <FormattedMessage id="S4lhkg" description="currentStatus" defaultMessage="Current Status"/>
              }
              options={formUtils.translateOptions(intl, militaryEnums.current_status)}
            />
            )}
            {collected_attributes.military_currently_transitioning && (
            <CustomRadioField
              name="military.currently_transitioning"
              label={
                <FormattedMessage id="mpgPQB" description="currentlyTransitioning" defaultMessage="Currently Transitioning?"/>
              }
              id="uu_contact_military_transitioning"
              options={formUtils.translateOptions(intl, militaryEnums.transitioning)}
            />
            )}
            {collected_attributes.military_at_least_one_day_active_duty && (
            <CustomRadioField
              name="military.at_least_one_day_active_duty"
              label={
                <FormattedMessage id="OftAFK" description="activeDutyOutsideBasicTraining" defaultMessage="Has at least one day of active duty outside of basic training?"/>
              }
              id="uu_contact_military_at_least_one_day_active_duty"
              options={formUtils.translateOptions(intl, militaryEnums.active_duty)}
            />
            )}
            {collected_attributes.military_branch && (
            <CustomSelectField
              name="military.branch"
              id="uu_contact_military_branch"
              label={
                <FormattedMessage id="ksVysG" description="branchOfService" defaultMessage="Branch of Service"/>
              }
              options={formUtils.translateOptions(intl, militaryEnums.branch)}
            />
            )}
            {collected_attributes.military_service_era && (
            <CustomSelectField
              name="military.service_era"
              id="uu_contact_military_era"
              label={
                <FormattedMessage id="QfAiMz" description="serviceEra" defaultMessage="Service Era"/>
              }
              options={formUtils.translateOptions(intl, militaryEnums.service_era)}
            />
            )}

            <div>
              <div className="inline-block w-40 mr-36">
                {collected_attributes.military_entry_date && (
                <CustomDateField
                  name="military.entry_date"
                  id="uu_military_entry_date"
                  label={
                    <FormattedMessage id="Z+MyZ5" description="serviceDates" defaultMessage="Service Dates"/>
                  }
                  other={{
                    maxDate: yesterdayDate,
                  }}
                />
                )}
              </div>
              <div className="inline-block w-40">
                {collected_attributes.military_exit_date && (
                <CustomDateField
                  name="military.exit_date"
                  id="uu_military_exit_date"
                  other={{
                    maxDate: yesterdayDate,
                  }}
                />
                )}
              </div>
            </div>

            {collected_attributes.military_deployed && (
            <CustomSelectField
              name="military.deployed"
              id="uu_contact_military_deployed"
              label={
                <FormattedMessage id="mXwPa2" description="deployed" defaultMessage="Has ever been deployed?"/>
              }
              options={formUtils.translateOptions(intl, militaryEnums.deployed)}
            />
            )}

            {
              hasBeenDeployed ? (
                <div>
                  <div className="inline-block w-40 mr-36">
                    {collected_attributes.military_deployment_starts_at && (
                      <CustomDateField
                        name="military.deployment_starts_at"
                        id="uu_military_deployment_starts_at"
                        label={
                          <FormattedMessage id="PYboOT" description="deploymentDates" defaultMessage="Deployment Dates"/>
                        }
                        other={{
                          maxDate: yesterdayDate,
                        }}
                      />
                    )}
                  </div>
                  <div className="inline-block w-40">
                    {collected_attributes.military_deployment_ends_at && (
                      <CustomDateField
                        name="military.deployment_ends_at"
                        id="uu_military_deployment_ends_at"
                        other={{
                          maxDate: yesterdayDate,
                        }}
                      />
                    )}
                  </div>
                </div>
              ) : null
            }

            {collected_attributes.military_discharge_type && (
            <CustomSelectField
              name="military.discharge_type"
              id="uu_contact_military_discharge_type"
              label={<FormattedMessage
                id="G4Oq5T"
                description="militaryDischarge"
                defaultMessage="Military Discharge"
              />}
              options={formUtils.translateOptions(intl, militaryEnums.discharge_type)}
            />
            )}

            {collected_attributes.military_discharged_due_to_disability && (
            <CustomRadioField
              name="military.discharged_due_to_disability"
              label={
                <FormattedMessage id="2pCvO5" description="dischargedOrRetiredForDisability" defaultMessage="Discharged or retired from military service for a disability incurred in the line of duty?"/>
              }
              id="uu_contact_military_discharged_due_to_disability"
              options={formUtils.translateOptions(intl, militaryEnums.discharge_disability)}
            />
            )}

            {collected_attributes.military_service_connected_disability && (
            <CustomRadioField
              name="military.service_connected_disability"
              label={
                <FormattedMessage id="pUa8AK" description="hasVARatedSCDisability" defaultMessage="Has VA-rated Service-Connected Disability?"/>
              }
              id="uu_contact_military_connected_disability"
              options={formUtils.translateOptions(intl, militaryEnums.connected_disability)}
            />
            )}

            {
              hasVAServiceDisability && collected_attributes.military_service_connected_disability_rating ? (
                <CustomSelectField
                  name="military.service_connected_disability_rating"
                  id="uu_contact_military_service_connected_disability_rating"
                  label={
                    <FormattedMessage id="Lh1DOl" description="scDisabilityRating" defaultMessage="Service-Connected Disability Rating"/>
                  }
                  options={formUtils.translateOptions(intl, militaryEnums.disability_rating)}
                  shouldSort={false}
                />
              ) : null
            }

            {collected_attributes.military_proof_of_veteran_status && (
            <CustomRadioField
              name="military.proof_of_veteran_status"
              label={
                <FormattedMessage id="+Jybhz" description="proofOfVetStatus" defaultMessage="Proof of veteran status?"/>
              }
              id="uu_contact_military_proof_of_veteran_status"
              options={formUtils.translateOptions(intl, militaryEnums.proof_of_veteran_status)}
            />
            )}

            {
              hasVeteranStatusProof && collected_attributes.military_proof_type ? (
                <CustomSelectField
                  name="military.proof_type"
                  id="uu_contact_military_proof_type"
                  label={
                    <FormattedMessage id="rpyYaU" description="proofType" defaultMessage="Proof Type"/>
                  }
                  options={formUtils.translateOptions(intl, militaryEnums.proof_type)}
                />
              ) : null
            }
          </>
        ) : null
      }
      </div>
    ) : ''
  );
}

MilitaryForm.propTypes = {
  assistanceReqConfigs: PropTypes.object.isRequired,
  form: PropTypes.shape({ getFieldState: PropTypes.func.isRequired }).isRequired,
};

export default injectIntl(MilitaryForm);
