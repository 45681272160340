import { Field } from 'react-final-form';
import { DateField } from '@unite-us/ui';
import formUtils from '../utils/formUtils';

function CustomDateField({
  name, label, id, other, required, ...rest
}) {

  const composeValidators = (validators, language, options) => (value) => {
    return validators.reduce((error, validator) => error || validator(value, language, options), undefined);
  };
  
  return (
    <Field
      name={name}
      validate={
        composeValidators([
          required && formUtils.required(rest.language),
          formUtils.isDateValid(rest.language)
      ].filter((e) => e))}
    >
      {(params, index) => (
        <DateField
          label={label}
          id={id}
          inline={false}
          {...other}
          {...params}
          required={required}
        />
      )}
    </Field>
  );
}

export default CustomDateField;
