import axios from 'axios';
import { LEGACY_API_URL } from '../env.config.js';

const fetchCoreToken = async (legacyToken) => {
  const baseURL = LEGACY_API_URL;
  const response = await axios.get(`/tokens/${legacyToken}`, {
    baseURL,
  });

  const coreToken = response.data.data;
  return coreToken;
};

export default fetchCoreToken;
