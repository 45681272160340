import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import App from './App';
import {asyncWithLDProvider, LDProvider } from 'launchdarkly-react-client-sdk';
import { LAUNCH_DARKLY_KEY } from './env.config.js';
import getLocaleFromNavigator from './utils/getLocaleFromNavigator';

function loadLocaleData(locale){
  return import(`./compiled-lang/${locale}.json`);
}

async function renderWidgetApp(token, containerId = 'uu-container') {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_KEY,
    user: { key: 'unknown', anonymous: true },
    options: { bootstrap: 'localStorage',
    useReport: true, }
  });

  const navigatorLanguage = getLocaleFromNavigator(navigator.language);
  if (sessionStorage.getItem("language") == null) {
    sessionStorage.setItem("language", navigatorLanguage.value);
  }
  const currentLang = sessionStorage.getItem("language");
  const containerEl = document.getElementById(containerId);
  document.head.innerHTML += '<link rel="stylesheet" type="text/css" href="/static/main.css"></link>';

  const messages = await loadLocaleData(currentLang);
  document.querySelector('html').classList.add('react');

  if (containerEl) {
    ReactDOM.render(
        <React.StrictMode>
          <LDProvider>
            <IntlProvider defaultLocale='en' locale={currentLang} messages={messages}>
              <App token={token} />
            </IntlProvider>
          </LDProvider>
        </React.StrictMode>,
        containerEl,
    );
  } else {
    console.log('No container found with id', containerId)
  }
};


const mode = process.env.REACT_APP_MODE;
console.log('App mode is', mode);
// if (mode === 'web' || !mode) {
  renderWidgetApp(null, 'uu-container');
// }

window.Uniteus = window.Uniteus || {};
window.Uniteus.assistanceRequestWidget = renderWidgetApp;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
