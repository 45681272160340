import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { CORE_API, CORE_LEGACY_API_URL } from '../env.config.js';

import { locationAddressFieldNames } from '@unite-us/client-utils';

const createAssistanceRequest = async (coreToken, pdfFile, requestData, arSearchAndMatchEnabled = false) => {
  const { assistance_request, assistance_request: { requestor } } = requestData;
  const formData = new FormData();

  // required
  formData.append('assistance_request[description]', assistance_request.description);
  formData.append('assistance_request[service_type_id]', assistance_request.service_type_id);
  formData.append('assistance_request[group_id]', coreToken.provider_id);

  formData.append('assistance_request[requestor][first_name]', requestor.first_name);
  formData.append('assistance_request[requestor][last_name]', requestor.last_name);
  formData.append('assistance_request[requestor][date_of_birth]', moment.unix(requestor.date_of_birth).utc().format('YYYY-MM-DD'));
  formData.append('assistance_request[consent][data]', pdfFile);

  // optional
  requestor.middle_name_initial && formData.append('assistance_request[requestor][middle_name_initial]', requestor.middle_name_initial); // Not in form
  requestor.preferred_first_name && formData.append('assistance_request[requestor][preferred_first_name]', requestor.preferred_first_name); // Not in form
  formData.append('assistance_request[requestor][gender]', _.get(requestor,'gender', 'undisclosed'));
  formData.append('assistance_request[requestor][citizenship]', _.get(requestor,'citizenship', 'undisclosed')); // Not in form
  formData.append('assistance_request[requestor][ethnicity]', _.get(requestor,'ethnicity', 'undisclosed'));
  
  // sexuality fields
  const sexuality = _.get(requestor,'sexuality', []);
  for (var i = 0; i < sexuality.length; i++) {
    formData.append('assistance_request[requestor][sexuality][]', sexuality[i]);
  }
  if (_.includes(sexuality, 'other') && _.get(requestor,'sexuality_other')) {
    formData.append('assistance_request[requestor][sexuality_other]', _.get(requestor,'sexuality_other'));
  }

  formData.append('assistance_request[requestor][marital_status]', _.get(requestor,'marital_status', 'undisclosed')); // Not in form
  formData.append('assistance_request[requestor][race]', _.get(requestor,'race', 'undisclosed'));
  requestor.title && formData.append('assistance_request[requestor][title]', requestor.title); // Not in form
  requestor.suffix && formData.append('assistance_request[requestor][suffix]', requestor.suffix); // Not in form
  requestor.gross_monthly_income && formData.append('assistance_request[requestor][gross_monthly_income]', requestor.gross_monthly_income);

  if (
    assistance_request.form_response &&
    assistance_request.form_response.length > 0
  ) {
    const length = assistance_request.form_response.length;
    assistance_request.form_id && formData.append('assistance_request[requestor][form_response][form_id]', assistance_request.form_id);
    for (var i = 0; i < length; i++) {
      const form_response = assistance_request.form_response[i];
      assistance_request.form_response && formData.append('assistance_request[requestor][form_response][data][][name]', form_response.name);
      assistance_request.form_response && formData.append('assistance_request[requestor][form_response][data][][title]', form_response.title);
      assistance_request.form_response && formData.append('assistance_request[requestor][form_response][data][][value]', form_response.value);
      assistance_request.form_response && formData.append('assistance_request[requestor][form_response][data][][displayValue]', form_response.displayValue);
    }
  }

  const addressFieldNames = [
    ...locationAddressFieldNames,
    'address_type',
    'line_2',
  ];

  for (const fn of addressFieldNames) {
    if (requestor[fn]) {
      formData.append(`assistance_request[requestor][address][${fn}]`, requestor[fn]);
    }
  }

  if (requestor.military) {
    Object.keys(requestor.military).forEach((key) => {
      const val = requestor.military[key];

      if (val) {
        if (_.includes(key, '_date') || _.includes(key, 'deployment_starts_at') || _.includes(key, 'deployment_ends_at')){
          formData.append(`assistance_request[requestor][military][${key}]`, moment.unix(val).utc().format('YYYY-MM-DD'));
        } else {
          formData.append(`assistance_request[requestor][military][${key}]`, val);
        }
      }
    });
  }

  requestor.email_address && formData.append('assistance_request[requestor][email_address][email_address]', requestor.email_address);
  requestor.phone_number && formData.append('assistance_request[requestor][phone_number][phone_number]', requestor.phone_number);
  requestor.phone_number && formData.append('assistance_request[requestor][phone_number][phone_type]', 'unknown');

  const baseURL = arSearchAndMatchEnabled ? CORE_API : CORE_LEGACY_API_URL;

  try {
    const response = await axios.post(
      'assistance_requests',
      formData,
      {
        baseURL,
        headers: {
          Authorization: `Bearer ${coreToken.token}`,
          'X-Application-Source': 'widgets',
        },
      },
    );

    return response;
  } catch (error) {
    return error;
  }
};

export default createAssistanceRequest;
