/* eslint-disable */
import { jsPDF } from 'jspdf';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import LANGUAGES from './languageEnums';
import { validateDate } from '@unite-us/ui/dist/utils/Dates';
import moment from 'moment';

import "../fonts/Amiri-Regular-normal";
import "../fonts/Battambang-Regular-normal";
import "../fonts/HindSiliguri-Regular-normal";
import "../fonts/IBMPlexSansArabic-Regular-normal";
import "../fonts/IBMPlexSansKR-Regular-normal";
import "../fonts/NotoSansKayahLi-Regular-normal";
import "../fonts/NotoSansLaoLooped-Regular-normal";
import "../fonts/NotoSerifArmenian-Regular-normal";
import "../fonts/NotoSerifEthiopic-Regular-normal";
import "../fonts/Padauk-Regular-normal";
import "../fonts/Roboto-Light-normal";
import "../fonts/TiroGurmukhi-Regular-normal";
import "../fonts/ZCOOLXiaoWei-Regular-normal";
import "../fonts/ZenKakuGothicNew-Regular-normal";


const formUtils = {};

const consentLangs = LANGUAGES.supportedList;
const MIN_DATE = moment('01/01/1900', 'MM/DD/YYYY').utc();
const today = moment().utc();

formUtils.required = () => {
  return value => value ? undefined : <FormattedMessage
                                        id="GJBQfx"
                                        description="required"
                                        defaultMessage="Required"
                                        />
}

formUtils.isIntegerValidator= () => {
  return value => {
    if (!_.isEmpty(value) && !value.match(/^\d+$/)) {
      return <FormattedMessage
              id="EFC0Ew"
              description="enterAWholeNumberIncome"
              defaultMessage="Enter a whole number income"
      />;
    }
    return undefined;
  }
}

formUtils.isDateValid = () => {
  return value => {
    const date = moment.unix(value).utc();
    const invalidDate = validateDate(date, today, MIN_DATE, false, 'MM/DD/YYYY');

    if (!invalidDate) return undefined;
    
    if (date.isAfter(today, 'day')) {
      return <FormattedMessage
        id="h2bsL5"
        description="enterADateBefore"
        defaultMessage="Enter a date before {date}"
        values={{ date: today.format("MM/DD/YYYY") }}
      />;
    }

    return <FormattedMessage
      id="G3A47A"
      description="enterADateAfter"
      defaultMessage="Enter a date after {date}"
      values={{ date: MIN_DATE.format("MM/DD/YYYY") }}
    />;
  
  }
}

formUtils.translateOptions = (intl, elements) => elements.map((element) => {
  if (element.id) {
    const translated = intl.formatMessage(element)
    return {
      display_name: translated,
      value: element.value,
    }
  }
  return element;
});

formUtils.formatOptions = (opt) => (
  opt.map((o) => ({ label: o.display_name, value: o.value }))
);

formUtils.generatePDFDynamic = async ({
  base64SignatureImage,
  fullName,
  htmlContent,
  contactFullName,
}) => {
  const doc = new jsPDF("p", "pt", "a4", 'true');
  const localeCode = sessionStorage.getItem('language');
  const localeInfo = consentLangs.find((e) => e.value === localeCode);

  if (localeInfo.font !== undefined) {
    const consentHTML = htmlContent;
    const consentDiv = document.createElement("div");
    consentDiv.innerHTML = consentHTML;
    const consentText = consentDiv.innerText;
    doc.setFont(localeInfo.font, 'normal');
    doc.setFontSize(12);
    doc.text(consentText, 50, 50, {maxWidth: 500 });
  }
  else {
    await doc.html(htmlContent, {
      x: 30,
      y: 10,
      windowWidth: 760,
      width: 760,
      html2canvas: {
        onclone: function (clonedDoc, clonedReference) {
          clonedDoc.body.style.color = '#000';
          clonedDoc.body.style.fontSize = '40px';
          clonedDoc.body.style.fontFamily = 'Helvetiva,Arial,sans-serif';
          const title = clonedReference.querySelector('h3');
          title.style.fontWeight = 'bold';
          title.style.fontSize = '24px';
          clonedReference.querySelectorAll('a').forEach((e) => {
            e.style.color = '#000';
          });
        },
        scrollY: 0,
        scale: 0.70,
      },
    });
  }

  // --- signature
  doc.addImage(base64SignatureImage, 'PNG', 50, 220, 240, 80, '', 'FAST');

  doc.setDrawColor('#000');
  doc.setLineWidth(1);
  doc.line(35, 290, 200, 290);

  doc.setTextColor('#000');
  doc.setFontSize(10);
  doc.text(fullName.toUpperCase(), 35, 305);

  // --- date
  const today = new Date();
  var dateUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes()));
  const dateStr = dateUTC.toLocaleTimeString('en-US', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone: 'UTC',
    timeZoneName: 'short',
  });

  doc.setFontSize(9);
  doc.text(contactFullName, 90, 304)
  doc.text(dateStr, 35, 315);
  const formattedPdf =  new Blob([ doc.output('blob') ], { type : 'application/pdf'});

  return formattedPdf;
};

export default formUtils;
