/* eslint-disable quote-props */
export default {
  'time': {
    'duration': [
      {
        'display_name': '< 15m',
        'value': '1',
      },
      {
        'display_name': '15m',
        'value': '15',
      },
      {
        'display_name': '30m',
        'value': '30',
      },
      {
        'display_name': '45m',
        'value': '45',
      },
      {
        'display_name': '1h',
        'value': '60',
      },
      {
        'display_name': '1h 15m',
        'value': '75',
      },
      {
        'display_name': '1h 30m',
        'value': '90',
      },
      {
        'display_name': '1h 45m',
        'value': '105',
      },
      {
        'display_name': '2h',
        'value': '120',
      },
      {
        'display_name': '2h 15m',
        'value': '135',
      },
      {
        'display_name': '2h 30m',
        'value': '150',
      },
      {
        'display_name': '> 2h 30m',
        'value': '999999',
      },
    ],
  },
  'base': {
    'languages': [
      {
        'display_name': 'Afrikaans',
        'value': 'af',
        'enabled': true,
      },
      {
        'display_name': 'Albanian',
        'value': 'sq',
        'enabled': true,
      },
      {
        'display_name': 'American Sign Language',
        'value': 'american_sign_language',
      },
      {
        'display_name': 'Arabic',
        'value': 'ar',
        'enabled': true,
      },
      {
        'display_name': 'Armenian',
        'value': 'hy',
        'enabled': true,
      },
      {
        'display_name': 'Basque',
        'value': 'eu',
        'enabled': true,
      },
      {
        'display_name': 'Bengali',
        'value': 'bn',
        'enabled': true,
      },
      {
        'display_name': 'Bulgarian',
        'value': 'bg',
        'enabled': true,
      },
      {
        'display_name': 'Catalan',
        'value': 'ca',
        'enabled': true,
      },
      {
        'display_name': 'Central Khmer',
        'value': 'km',
        'enabled': true,
      },
      {
        'display_name': 'Chinese (Simplified)',
        'value': 'zh',
        'enabled': true,
      },
      {
        'display_name': 'Croatian',
        'value': 'hr',
        'enabled': true,
      },
      {
        'display_name': 'Czech',
        'value': 'cs',
        'enabled': true,
      },
      {
        'display_name': 'Danish',
        'value': 'da',
        'enabled': true,
      },
      {
        'display_name': 'Dutch',
        'value': 'nl',
        'enabled': true,
      },
      {
        'display_name': 'English',
        'value': 'en',
        'enabled': true,
      },
      {
        'display_name': 'Estonian',
        'value': 'et',
        'enabled': true,
      },
      {
        'display_name': 'Fijian',
        'value': 'fj',
        'enabled': true,
      },
      {
        'display_name': 'Finnish',
        'value': 'fi',
        'enabled': true,
      },
      {
        'display_name': 'French',
        'value': 'fr',
        'enabled': true,
      },
      {
        'display_name': 'Georgian',
        'value': 'ka',
        'enabled': true,
      },
      {
        'display_name': 'German',
        'value': 'de',
        'enabled': true,
      },
      {
        'display_name': 'Gujarati',
        'value': 'gu',
        'enabled': true,
      },
      {
        'display_name': 'Hebrew',
        'value': 'he',
        'enabled': true,
      },
      {
        'display_name': 'Haitian Creole' ,
        'value': 'ht',
        'enabled': true,
      },
      {
        'display_name': 'Hindi',
        'value': 'hi',
        'enabled': true,
      },
      {
        'display_name': 'Hungarian',
        'value': 'hu',
        'enabled': true,
      },
      {
        'display_name': 'Icelandic',
        'value': 'is',
        'enabled': true,
      },
      {
        'display_name': 'Indonesian',
        'value': 'id',
        'enabled': true,
      },
      {
        'display_name': 'Irish',
        'value': 'ga',
        'enabled': true,
      },
      {
        'display_name': 'Italian',
        'value': 'it',
        'enabled': true,
      },
      {
        'display_name': 'Japanese',
        'value': 'ja',
        'enabled': true,
      },
      {
        'display_name': 'Korean',
        'value': 'ko',
        'enabled': true,
      },
      {
        'display_name': 'Latin',
        'value': 'la',
        'enabled': true,
      },
      {
        'display_name': 'Latvian',
        'value': 'lv',
        'enabled': true,
      },
      {
        'display_name': 'Lithuanian',
        'value': 'lt',
        'enabled': true,
      },
      {
        'display_name': 'Macedonian',
        'value': 'mk',
        'enabled': true,
      },
      {
        'display_name': 'Malay',
        'value': 'ms',
        'enabled': true,
      },
      {
        'display_name': 'Malayalam',
        'value': 'ml',
        'enabled': true,
      },
      {
        'display_name': 'Maltese',
        'value': 'mt',
        'enabled': true,
      },
      {
        'display_name': 'Maori',
        'value': 'mi',
        'enabled': true,
      },
      {
        'display_name': 'Marathi',
        'value': 'mr',
        'enabled': true,
      },
      {
        'display_name': 'Modern Greek (1453-)',
        'value': 'el',
        'enabled': true,
      },
      {
        'display_name': 'Mongolian',
        'value': 'mn',
        'enabled': true,
      },
      {
        'display_name': 'Nepali',
        'value': 'ne',
        'enabled': true,
      },
      {
        'display_name': 'Norwegian',
        'value': 'no',
        'enabled': true,
      },
      {
        'display_name': 'Panjabi',
        'value': 'pa',
        'enabled': true,
      },
      {
        'display_name': 'Persian',
        'value': 'fa',
        'enabled': true,
      },
      {
        'display_name': 'Polish',
        'value': 'pl',
        'enabled': true,
      },
      {
        'display_name': 'Portuguese',
        'value': 'pt',
        'enabled': true,
      },
      {
        'display_name': 'Quechua',
        'value': 'qu',
        'enabled': true,
      },
      {
        'display_name': 'Romanian',
        'value': 'ro',
        'enabled': true,
      },
      {
        'display_name': 'Russian',
        'value': 'ru',
        'enabled': true,
      },
      {
        'display_name': 'Samoan',
        'value': 'sm',
        'enabled': true,
      },
      {
        'display_name': 'Serbian',
        'value': 'sr',
        'enabled': true,
      },
      {
        'display_name': 'Slovak',
        'value': 'sk',
        'enabled': true,
      },
      {
        'display_name': 'Slovenian',
        'value': 'sl',
        'enabled': true,
      },
      {
        'display_name': 'Spanish',
        'value': 'es',
        'enabled': true,
      },
      {
        'display_name': 'Swahili',
        'value': 'sw',
        'enabled': true,
      },
      {
        'display_name': 'Swedish',
        'value': 'sv',
        'enabled': true,
      },
      {
        'display_name': 'Tamil',
        'value': 'ta',
        'enabled': true,
      },
      {
        'display_name': 'Tatar',
        'value': 'tt',
        'enabled': true,
      },
      {
        'display_name': 'Telugu',
        'value': 'te',
        'enabled': true,
      },
      {
        'display_name': 'Thai',
        'value': 'th',
        'enabled': true,
      },
      {
        'display_name': 'Tibetan',
        'value': 'bo',
        'enabled': true,
      },
      {
        'display_name': 'Tonga (Tonga Islands)',
        'value': 'to',
        'enabled': true,
      },
      {
        'display_name': 'Turkish',
        'value': 'tr',
        'enabled': true,
      },
      {
        'display_name': 'Ukrainian',
        'value': 'uk',
        'enabled': true,
      },
      {
        'display_name': 'Urdu',
        'value': 'ur',
        'enabled': true,
      },
      {
        'display_name': 'Uzbek',
        'value': 'uz',
        'enabled': true,
      },
      {
        'display_name': 'Vietnamese',
        'value': 'vi',
        'enabled': true,
      },
      {
        'display_name': 'Welsh',
        'value': 'cy',
        'enabled': true,
      },
      {
        'display_name': 'Xhosa',
        'value': 'xh',
        'enabled': true,
      },
    ],
    'days_of_week': [
      {
        'display_name': 'Monday',
        'value': 'monday',
      },
      {
        'display_name': 'Tuesday',
        'value': 'tuesday',
      },
      {
        'display_name': 'Wednesday',
        'value': 'wednesday',
      },
      {
        'display_name': 'Thursday',
        'value': 'thursday',
      },
      {
        'display_name': 'Friday',
        'value': 'friday',
      },
      {
        'display_name': 'Saturday',
        'value': 'saturday',
      },
      {
        'display_name': 'Sunday',
        'value': 'sunday',
      },
    ],
  },
  'notifications': {
    'outgoing_messaging_options': [
      {
        'display_name': 'off',
        'value': 'off',
      },
      {
        'display_name': 'email_only',
        'value': 'email_only',
      },
    ],
  },
  'messages': {
    'type': [
      {
        'display_name': 'Notification',
        'value': 'notification',
      },
      {
        'display_name': 'Message',
        'value': 'message',
      },
    ],
    'method': [
      {
        'display_name': 'Email',
        'value': 'email',
      },
      {
        'display_name': 'Text (SMS)',
        'value': 'sms',
      },
    ],
  },
  'emr': {
    'organizations': [
      {
        'display_name': 'AllScripts',
        'value': 'allscripts',
      },
      {
        'display_name': 'Cerner',
        'value': 'cerner',
      },
      {
        'display_name': 'EPIC',
        'value': 'epic',
      },
      {
        'display_name': 'NextGen',
        'value': 'nextgen',
      },
    ],
  },
  'forms': {
    'response_types': [
      {
        'display_name': 'string',
        'value': 'string',
      },
      {
        'display_name': 'integer',
        'value': 'integer',
      },
      {
        'display_name': 'float',
        'value': 'float',
      },
      {
        'display_name': 'array',
        'value': 'array',
      },
      {
        'display_name': 'boolean',
        'value': 'boolean',
      },
    ],
    'usage_context_types': [
      {
        'display_name': 'Intake',
        'value': 'intake',
      },
    ],
    'relationship_types': [
      {
        'display_name': 'general',
        'value': 'general',
      },
      {
        'display_name': 'intake',
        'value': 'intake',
      },
      {
        'display_name': 'exit',
        'value': 'exit',
      },
    ],
    'input_types': [
      {
        'display_name': 'text',
        'value': 'text',
      },
      {
        'display_name': 'select',
        'value': 'select',
      },
      {
        'display_name': 'radio',
        'value': 'radio',
      },
      {
        'display_name': 'checkbox',
        'value': 'checkbox',
      },
      {
        'display_name': 'textarea',
        'value': 'textarea',
      },
      {
        'display_name': 'date',
        'value': 'date',
      },
      {
        'display_name': 'email',
        'value': 'email',
      },
      {
        'display_name': 'password',
        'value': 'password',
      },
      {
        'display_name': 'number',
        'value': 'number',
      },
      {
        'display_name': 'list',
        'value': 'list',
      },
      {
        'display_name': 'block',
        'value': 'block',
      },
      {
        'display_name': 'duration',
        'value': 'duration',
      },
    ],
    'help_text_position': [
      {
        'display_name': 'before_input',
        'value': 'before_input',
      },
      {
        'display_name': 'after_input',
        'value': 'after_input',
      },
    ],
    'section_types': [
      {
        'display_name': 'form_group',
        'value': 'form_group',
      },
      {
        'display_name': 'break',
        'value': 'break',
      },
    ],
    'conditional_display_visibility': [
      {
        'display_name': 'show',
        'value': 'show',
      },
      {
        'display_name': 'hide',
        'value': 'hide',
      },
    ],
    'conditional_display_conditions': [
      {
        'display_name': 'is',
        'value': 'is',
      },
      {
        'display_name': 'is_not',
        'value': 'is_not',
      },
      {
        'display_name': 'greater_than',
        'value': 'greater_than',
      },
      {
        'display_name': 'less_than',
        'value': 'less_than',
      },
      {
        'display_name': 'contains',
        'value': 'contains',
      },
      {
        'display_name': 'does_not_contain',
        'value': 'does_not_contain',
      },
      {
        'display_name': 'is_blank',
        'value': 'is_blank',
      },
      {
        'display_name': 'is_not_blank',
        'value': 'is_not_blank',
      },
    ],
    'validator_types': [
      {
        'display_name': 'Numericality',
        'value': 'numericality',
      },
      {
        'display_name': 'Presence',
        'value': 'presence',
      },
    ],
  },
  'service_cases': {
    'statuses': [
      {
        'display_name': 'Closed',
        'value': 'closed',
      },
      {
        'display_name': 'Open',
        'value': 'open',
      },
    ],
  },
  'referrals': {
    'statuses': [
      {
        'display_name': 'Draft',
        'value': 'draft',
      },
      {
        'display_name': 'Needs Action',
        'value': 'pending',
      },
      {
        'display_name': 'In Review',
        'value': 'in_review',
      },
      {
        'display_name': 'Accepted',
        'value': 'accepted',
      },
      {
        'display_name': 'Rejected',
        'value': 'declined',
      },
      {
        'display_name': 'Recalled',
        'value': 'recalled',
      },
      {
        'display_name': 'Closed',
        'value': 'closed',
      },
      {
        'display_name': 'Auto Recalled',
        'value': 'auto_recalled',
      },
    ],
    'hold': [
      {
        'display_name': 'Conducting additional screening/follow-up',
        'value': 'Conducting additional screening/follow-up',
      },
      {
        'display_name': 'Scheduling an appointment with client',
        'value': 'Scheduling an appointment with client',
      },
      {
        'display_name': 'Waiting to receive documents',
        'value': 'Waiting to receive documents',
      },
      {
        'display_name': 'Other',
        'value': 'Other',
      },
    ],
    'recall': [
      {
        'display_name': 'Client No Longer Requires Service',
        'value': 'Client No Longer Requires Service',
      },
      {
        'display_name': 'Recipient Organization Did Not Respond',
        'value': 'Recipient Organization Did Not Respond',
      },
      {
        'display_name': 'Other',
        'value': 'Other',
      },
    ],
    'rejection': [
      {
        'display_name': 'Not Eligible',
        'value': 'Client is not eligible for our services',
      },
      {
        'display_name': 'Duplicate',
        'value': 'Duplicate, case already exists in the system',
      },
      {
        'display_name': 'No Capacity',
        'value': 'We do not have capacity to serve client',
      },
      {
        'display_name': 'Do Not Provide Service',
        'value': 'We do not provide the services requested/needed',
      },
      {
        'display_name': 'Unable to Contact Client',
        'value': 'We were unable to contact the client',
      },
      {
        'display_name': 'Other',
        'value': 'Other',
      },
    ],
    'dashboard_statuses': [
      {
        'display_name': 'Pending Consent',
        'value': 'pending_consent',
      },
      {
        'display_name': 'Declined Consent',
        'value': 'declined_consent',
      },
      {
        'display_name': 'Needs Action',
        'value': 'pending',
      },
      {
        'display_name': 'In Review',
        'value': 'in_review',
      },
      {
        'display_name': 'Accepted',
        'value': 'accepted',
      },
      {
        'display_name': 'Rejected',
        'value': 'declined',
      },
      {
        'display_name': 'Recalled',
        'value': 'recalled',
      },
      {
        'display_name': 'Closed',
        'value': 'closed',
      },
      {
        'display_name': 'Auto Recalled',
        'value': 'auto_recalled',
      },
      {
        'display_name': 'Recalled or Auto-Recalled',
        'value': 'recalled_or_auto_recalled',
      },
    ],
  },
  'search': {
    'available_record_types': [
      {
        'display_name': 'All Record Types',
        'value': 'any',
      },
      {
        'display_name': 'Cases',
        'value': 'cases_servicecase',
      },
      {
        'display_name': 'Clients',
        'value': 'contact',
      },
      {
        'display_name': 'Documents',
        'value': 'document',
      },
      {
        'display_name': 'Interactions',
        'value': 'interactions_base',
      },
      {
        'display_name': 'Referrals',
        'value': 'referrals_referral',
      },
    ],
    'execution_type': [
      {
        'display_name': 'Version 1',
        'value': 'v1',
      },
      {
        'display_name': 'Version 2',
        'value': 'v2',
      },
    ],
  },
  'households': {
    'manifest': {
      'relation_type': [
        {
          'display_name': 'Parent',
          'value': 'HOUSEHOLD_RELATION_PARENT',
        },
        {
          'display_name': 'Self',
          'value': 'HOUSEHOLD_RELATION_SELF',
        },
        {
          'display_name': 'Child',
          'value': 'HOUSEHOLD_RELATION_CHILD',
        },
        {
          'display_name': 'Spouse',
          'value': 'HOUSEHOLD_RELATION_SPOUSE',
        },
        {
          'display_name': 'Partner',
          'value': 'HOUSEHOLD_RELATION_PARTNER',
        },
        {
          'display_name': 'Other Relation',
          'value': 'HOUSEHOLD_RELATION_OTHER_RELATION',
        },
        {
          'display_name': 'Non-Relation',
          'value': 'HOUSEHOLD_RELATION_NON_RELATION',
        },
      ],
    },
    'relation_type': [
      {
        'display_name': 'Self',
        'value': 'self',
      },
      {
        'display_name': 'Child',
        'value': 'child',
      },
      {
        'display_name': 'Spouse',
        'value': 'spouse',
      },
      {
        'display_name': 'Parent',
        'value': 'parent',
      },
      {
        'display_name': 'Partner',
        'value': 'partner',
      },
      {
        'display_name': 'Other Relation',
        'value': 'other-relation',
      },
      {
        'display_name': 'Non-Relation',
        'value': 'non-relation',
      },
    ],
  },
  'networks': {
    'network_types': [
      {
        'display_name': 'Coordinated',
        'value': 'coordinated',
      },
      {
        'display_name': 'Coordinated with Provider to Provider Channels',
        'value': 'coordinated_with_p2p',
      },
      {
        'display_name': 'Decentralized',
        'value': 'decentralized',
      },
      {
        'display_name': 'Hub and Spoke',
        'value': 'hub_and_spoke',
      },
    ],
    'referral_scope': [
      {
        'display_name': 'Coordination Center Only',
        'value': 'coordination_center_only',
      },
      {
        'display_name': 'All Organizations',
        'value': 'all_groups',
      },
      {
        'display_name': 'Subset of Organizations',
        'value': 'some_groups',
      },
    ],
  },
  'consent': {
    'manifest': {
      'method_types': [
        {
          'display_name': 'Attestation',
          'value': 'CONSENT_REQUEST_METHOD_TYPE_ATTESTATION',
        },
        {
          'display_name': 'Document',
          'value': 'CONSENT_REQUEST_METHOD_TYPE_DOCUMENT',
        },
        {
          'display_name': 'Email',
          'value': 'CONSENT_REQUEST_METHOD_TYPE_EMAIL',
        },
        {
          'display_name': 'Web',
          'value': 'CONSENT_REQUEST_METHOD_TYPE_WEB',
        },
        {
          'display_name': 'SMS',
          'value': 'CONSENT_REQUEST_METHOD_TYPE_SMS',
        },
      ],
    },
    'contact_status': [
      {
        'display_name': 'Needs Consent',
        'value': 'unsent',
      },
      {
        'display_name': 'Pending',
        'value': 'pending',
      },
      {
        'display_name': 'Accepted',
        'value': 'accepted',
      },
      {
        'display_name': 'Declined',
        'value': 'rejected',
      },
    ],
  },
  'contact_data': {
    'manifest': {
      'communication_types': [
        {
          'display_name': 'Call',
          'value': 'COMMUNICATION_TYPES_PHONE_CALL',
        },
        {
          'display_name': 'Message',
          'value': 'COMMUNICATION_TYPES_MESSAGE',
        },
        {
          'display_name': 'Notification',
          'value': 'COMMUNICATION_TYPES_NOTIFICATION',
        },
      ],
      'phone_types': [
        {
          'display_name': 'Mobile',
          'value': 'PHONE_TYPE_MOBILE',
        },
        {
          'display_name': 'Home',
          'value': 'PHONE_TYPE_HOME',
        },
        {
          'display_name': 'Work',
          'value': 'PHONE_TYPE_WORK',
        },
        {
          'display_name': 'Fax',
          'value': 'PHONE_TYPE_FAX',
        },
        {
          'display_name': 'Unknown',
          'value': 'PHONE_TYPE_UNKNOWN',
        },
      ],
      'country_code': [
        {
          'display_name': '1',
          'value': '1',
        },
      ],
      'client_scopes': [
        {
          'display_name': 'Network',
          'value': 'SCOPE_REQUEST_TYPE_NETWORK',
        },
        {
          'display_name': 'Group',
          'value': 'SCOPE_REQUEST_TYPE_GROUP',
        },
      ],
      'address_types': [
        {
          'display_name': 'Home',
          'value': 'ADDRESS_TYPE_HOME',
        },
        {
          'display_name': 'Mailing',
          'value': 'ADDRESS_TYPE_MAILING',
        },
        {
          'display_name': 'Work',
          'value': 'ADDRESS_TYPE_WORK',
        },
        {
          'display_name': 'Unknown',
          'value': 'ADDRESS_TYPE_UNKNOWN',
        },
      ],
      'insurance_types': [
        {
          'display_name': 'Medicaid',
          'value': 'INSURANCE_TYPE_MEDICAID',
        },
        {
          'display_name': 'Medicare',
          'value': 'INSURANCE_TYPE_MEDICARE',
        },
      ],
      'military': {
        'affiliation': [
          {
            'display_name': 'Military Member or Veteran',
            'value': 'AFFILIATION_MILITARY_MEMBER_OR_VETERAN',
          },
          {
            'display_name': 'Military Spouse',
            'value': 'AFFILIATION_SPOUSE',
          },
          {
            'display_name': 'Widow/er',
            'value': 'AFFILIATION_WIDOW',
          },
          {
            'display_name': 'Family Member',
            'value': 'AFFILIATION_FAMILY_MEMBER',
          },
          {
            'display_name': 'Caregiver',
            'value': 'AFFILIATION_CAREGIVER',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'AFFILIATION_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'AFFILIATION_NO_RESPONSE',
          },
        ],
        'current_status': [
          {
            'display_name': 'Active Duty',
            'value': 'CURRENT_STATUS_ACTIVE_DUTY',
          },
          {
            'display_name': 'National Guard',
            'value': 'CURRENT_STATUS_NATIONAL_GUARD',
          },
          {
            'display_name': 'Reserve',
            'value': 'CURRENT_STATUS_RESERVE',
          },
          {
            'display_name': 'Retiree',
            'value': 'CURRENT_STATUS_RETIREE',
          },
          {
            'display_name': 'Veteran',
            'value': 'CURRENT_STATUS_VETERAN',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'CURRENT_STATUS_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'CURRENT_STATUS_NO_RESPONSE',
          },
        ],
        'transitioning': [
          {
            'display_name': 'Yes, within 12 months before separation',
            'value': 'TRANSITIONING_YES_12MOS_BEFORE',
          },
          {
            'display_name': 'Yes, within 12 months after separation',
            'value': 'TRANSITIONING_YES_12MOS_AFTER',
          },
          {
            'display_name': 'Not Transitioning',
            'value': 'TRANSITIONING_NO',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'TRANSITIONING_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'TRANSITIONING_NO_RESPONSE',
          },
        ],
        'active_duty': [
          {
            'display_name': 'Yes',
            'value': 'MILITARY_YES_NO_YES',
          },
          {
            'display_name': 'No',
            'value': 'MILITARY_YES_NO_NO',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'MILITARY_YES_NO_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'MILITARY_YES_NO_NO_RESPONSE',
          },
        ],
        'branch': [
          {
            'display_name': 'Air Force',
            'value': 'BRANCH_AIR_FORCE',
          },
          {
            'display_name': 'Army',
            'value': 'BRANCH_ARMY',
          },
          {
            'display_name': 'Coast Guard',
            'value': 'BRANCH_COAST_GUARD',
          },
          {
            'display_name': 'Marine Corps',
            'value': 'BRANCH_MARINES',
          },
          {
            'display_name': 'Navy',
            'value': 'BRANCH_NAVY',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'BRANCH_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'BRANCH_NO_RESPONSE',
          },
        ],
        'service_era': [
          {
            'display_name': 'Post 9/11 (on or After 9/11/2001)',
            'value': 'SERVICE_ERA_POST_911',
          },
          {
            'display_name': 'Persian Gulf War (on or After 8/2/1990)',
            'value': 'SERVICE_ERA_PERSIAN_GULF',
          },
          {
            'display_name': 'Post-Vietnam (5/8/1975-8/1/1990)',
            'value': 'SERVICE_ERA_POST_VIETNAM',
          },
          {
            'display_name': 'Vietnam Era (2/28/1961-5/7/1975)',
            'value': 'SERVICE_ERA_VIETNAM',
          },
          {
            'display_name': 'Post-Korean (2/1/1955-2/27/1961)',
            'value': 'SERVICE_ERA_POST_KOREAN',
          },
          {
            'display_name': 'Korean War (6/27/1950-1/31/1955)',
            'value': 'SERVICE_ERA_KOREAN_WAR',
          },
          {
            'display_name': 'Pre-Korean (1/1/1947-6/27/1950)',
            'value': 'SERVICE_ERA_PRE_KOREAN',
          },
          {
            'display_name': 'World War 2 (12/7/1941-12/31/1946)',
            'value': 'SERVICE_ERA_WW2',
          },
          {
            'display_name': 'Merchant Marine (12/7/1941-8/15/1945)',
            'value': 'SERVICE_ERA_MERCHANT_MARINE',
          },
          {
            'display_name': 'World War 1 (4/6/1917-11/11/1918)',
            'value': 'SERVICE_ERA_WW1',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'SERVICE_ERA_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'SERVICE_ERA_NO_RESPONSE',
          },
        ],
        'deployed': [
          {
            'display_name': 'Yes',
            'value': 'MILITARY_YES_NO_YES',
          },
          {
            'display_name': 'No',
            'value': 'MILITARY_YES_NO_NO',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'MILITARY_YES_NO_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'MILITARY_YES_NO_NO_RESPONSE',
          },
        ],
        'discharge_type': [
          {
            'display_name': 'Honorable',
            'value': 'DISCHARGE_TYPE_HONORABLE',
          },
          {
            'display_name': 'General',
            'value': 'DISCHARGE_TYPE_GENERAL',
          },
          {
            'display_name': 'Other than Honorable',
            'value': 'DISCHARGE_TYPE_OTHER_THAN_HONORABLE',
          },
          {
            'display_name': 'Bad Conduct',
            'value': 'DISCHARGE_TYPE_BAD_CONDUCT',
          },
          {
            'display_name': 'Dishonorable',
            'value': 'DISCHARGE_TYPE_DISHONORABLE',
          },
          {
            'display_name': 'Uncharacterized',
            'value': 'DISCHARGE_TYPE_UNCHARACTERIZED',
          },
          {
            'display_name': 'Other',
            'value': 'DISCHARGE_TYPE_OTHER',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'DISCHARGE_TYPE_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'DISCHARGE_TYPE_NO_RESPONSE',
          },
        ],
        'discharge_disability': [
          {
            'display_name': 'Yes',
            'value': 'MILITARY_YES_NO_YES',
          },
          {
            'display_name': 'No',
            'value': 'MILITARY_YES_NO_NO',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'MILITARY_YES_NO_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'MILITARY_YES_NO_NO_RESPONSE',
          },
        ],
        'connected_disability': [
          {
            'display_name': 'Yes',
            'value': 'MILITARY_YES_NO_YES',
          },
          {
            'display_name': 'No',
            'value': 'MILITARY_YES_NO_NO',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'MILITARY_YES_NO_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'MILITARY_YES_NO_NO_RESPONSE',
          },
        ],
        'disability_rating': [
          {
            'display_name': '0%',
            'value': 'DISABILITY_RATING_0',
          },
          {
            'display_name': '10%',
            'value': 'DISABILITY_RATING_10',
          },
          {
            'display_name': '20%',
            'value': 'DISABILITY_RATING_20',
          },
          {
            'display_name': '30%',
            'value': 'DISABILITY_RATING_30',
          },
          {
            'display_name': '40%',
            'value': 'DISABILITY_RATING_40',
          },
          {
            'display_name': '50%',
            'value': 'DISABILITY_RATING_50',
          },
          {
            'display_name': '60%',
            'value': 'DISABILITY_RATING_60',
          },
          {
            'display_name': '70%',
            'value': 'DISABILITY_RATING_70',
          },
          {
            'display_name': '80%',
            'value': 'DISABILITY_RATING_80',
          },
          {
            'display_name': '90%',
            'value': 'DISABILITY_RATING_90',
          },
          {
            'display_name': '100%',
            'value': 'DISABILITY_RATING_100',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'DISABILITY_RATING_NO_RESPONSE',
          },
          {
            'display_name': 'No Response',
            'value': 'DISABILITY_RATING_NO_RESPONSE',
          },
        ],
        'proof_of_veteran_status': [
          {
            'display_name': 'Yes',
            'value': 'MILITARY_YES_NO_YES',
          },
          {
            'display_name': 'No',
            'value': 'MILITARY_YES_NO_NO',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'MILITARY_YES_NO_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'MILITARY_YES_NO_NO_RESPONSE',
          },
        ],
        'proof_type': [
          {
            'display_name': 'DD214',
            'value': 'PROOF_TYPE_DD214',
          },
          {
            'display_name': 'VA ID',
            'value': 'PROOF_TYPE_VA_ID',
          },
          {
            'display_name': 'Statement of Service',
            'value': 'PROOF_TYPE_STATEMENT_OF_SERVICE',
          },
          {
            'display_name': 'Retiree ID',
            'value': 'PROOF_TYPE_RETIREE_ID',
          },
          {
            'display_name': 'Drivers License',
            'value': 'PROOF_TYPE_DRIVERS_LICENSE',
          },
          {
            'display_name': 'Military ID',
            'value': 'PROOF_TYPE_MILITARY_ID',
          },
          {
            'display_name': 'Other',
            'value': 'PROOF_TYPE_OTHER',
          },
          {
            'display_name': 'Prefer Not to Disclose',
            'value': 'PROOF_TYPE_PREFER_NOT_TO_DISCLOSE',
          },
          {
            'display_name': 'No Response',
            'value': 'PROOF_TYPE_NO_RESPONSE',
          },
        ],
      },
    },
    'communication_preferences': {
      'manifest': {
        'contact_method': [
          {
            'display_name': 'Call',
            'value': 'PREFERRED_COMMUNICATION_METHOD_PHONE_CALL',
          },
          {
            'display_name': 'Text',
            'value': 'PREFERRED_COMMUNICATION_METHOD_SMS',
          },
          {
            'display_name': 'Email',
            'value': 'PREFERRED_COMMUNICATION_METHOD_EMAIL',
          },
          {
            'display_name': 'No Preference',
            'value': 'PREFERRED_COMMUNICATION_METHOD_NO_PREFERENCE',
          },
        ],
        'time_of_day': [
          {
            'display_name': 'Morning',
            'value': 'PREFERRED_COMMUNICATION_TIME_OF_DAY_MORNING',
          },
          {
            'display_name': 'Afternoon',
            'value': 'PREFERRED_COMMUNICATION_TIME_OF_DAY_AFTERNOON',
          },
          {
            'display_name': 'Evening',
            'value': 'PREFERRED_COMMUNICATION_TIME_OF_DAY_EVENING',
          },
          {
            'display_name': 'Any Time',
            'value': 'PREFERRED_COMMUNICATION_TIME_OF_DAY_ANY_TIME',
          },
        ],
      },
      'contact_method': [
        {
          'display_name': 'Call',
          'value': 'phone_call',
        },
        {
          'display_name': 'Text',
          'value': 'sms',
        },
        {
          'display_name': 'Email',
          'value': 'email',
        },
        {
          'display_name': 'No Preference',
          'value': 'no_preference',
        },
      ],
      'time_of_day': [
        {
          'display_name': 'Any Time',
          'value': 'any_time',
        },
        {
          'display_name': 'Morning',
          'value': 'morning',
        },
        {
          'display_name': 'Afternoon',
          'value': 'afternoon',
        },
        {
          'display_name': 'Evening',
          'value': 'evening',
        },
      ],
    },
    'communication_types': [
      {
        'display_name': 'Call',
        'value': 'phone_call',
      },
      {
        'display_name': 'Message',
        'value': 'message',
      },
      {
        'display_name': 'Notification',
        'value': 'notification',
      },
    ],
    'phone_types': [
      {
        'display_name': 'Mobile',
        'value': 'mobile',
      },
      {
        'display_name': 'Home',
        'value': 'home',
      },
      {
        'display_name': 'Work',
        'value': 'work',
      },
      {
        'display_name': 'Fax',
        'value': 'fax',
      },
      {
        'display_name': 'Unknown',
        'value': 'unknown',
      },
    ],
    'address_types': [
      {
        'id': 'nulrMy',
        'display_name': 'Home',
        'value': 'home',
      },
      {
        'id': '33TwMn',
        'display_name': 'Mailing',
        'value': 'mailing',
      },
      {
        'id': 'Jgt4fR',
        'display_name': 'Work',
        'value': 'work',
      },
      {
        'id': 'T82R2Z',
        'display_name': 'Unknown',
        'value': 'unknown',
      },
    ],
    'military': {
      'affiliation': [
        {
          'id': 'YhAJ3K',
          'display_name': 'Military Member or Veteran',
          'value': 'military_member_or_veteran',
        },
        {
          'id': 'sqHW7g',
          'display_name': 'Military Spouse',
          'value': 'spouse',
        },
        {
          'id': 'NI0Ip3',
          'display_name': 'Widow/er',
          'value': 'widow',
        },
        {
          'id': '+Nn4jS',
          'display_name': 'Family Member',
          'value': 'family_member',
        },
        {
          'id': 'HcSe+5',
          'display_name': 'Caregiver',
          'value': 'caregiver',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'current_status': [
        {
          'id': 'BtaSJW',
          'display_name': 'Active Duty',
          'value': 'active_duty',
        },
        {
          'id': 'CUUHxE',
          'display_name': 'National Guard',
          'value': 'national_guard',
        },
        {
          'id': 'xdTLTH',
          'display_name': 'Reserve',
          'value': 'reserve',
        },
        {
          'id': 'qptoBb',
          'display_name': 'Retiree',
          'value': 'retiree',
        },
        {
          'id': 'REi4/1',
          'display_name': 'Veteran',
          'value': 'veteran',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'transitioning': [
        {
          'id': 'oGXzfz',
          'display_name': 'Yes, within 12 months before separation',
          'value': 'yes_12mos_before',
        },
        {
          'id': 'pVIuSy',
          'display_name': 'Yes, within 12 months after separation',
          'value': 'yes_12mos_after',
        },
        {
          'id': 'PBKRHU',
          'display_name': 'Not Transitioning',
          'value': 'no',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'active_duty': [
        {
          'id': 'V9M1BS',
          'display_name': 'Yes',
          'value': 'yes',
        },
        {
          'id': '120scI',
          'display_name': 'No',
          'value': 'no',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'branch': [
        {
          'id': 'o2THDY',
          'display_name': 'Air Force',
          'value': 'air_force',
        },
        {
          'id': 'LEbt/V',
          'display_name': 'Army',
          'value': 'army',
        },
        {
          'id': 'UGDO5k',
          'display_name': 'Coast Guard',
          'value': 'coast_guard',
        },
        {
          'id': 'p1p3jS',
          'display_name': 'Marine Corps',
          'value': 'marines',
        },
        {
          'id': 'yBZNAs',
          'display_name': 'Navy',
          'value': 'navy',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'service_era': [
        {
          'id': 'g/oihp',
          'display_name': 'Post 9/11 (on or After 9/11/2001)',
          'value': 'post_911',
        },
        {
          'id': 'pIqtmR',
          'display_name': 'Persian Gulf War (on or After 8/2/1990)',
          'value': 'persian_gulf',
        },
        {
          'id': 'C6WT+x',
          'display_name': 'Post-Vietnam (5/8/1975-8/1/1990)',
          'value': 'post_vietnam',
        },
        {
          'id': '6WvAyk',
          'display_name': 'Vietnam Era (2/28/1961-5/7/1975)',
          'value': 'vietnam',
        },
        {
          'id': 'eaPYPj',
          'display_name': 'Post-Korean (2/1/1955-2/27/1961)',
          'value': 'post_korean',
        },
        {
          'id': 'iQqnww',
          'display_name': 'Korean War (6/27/1950-1/31/1955)',
          'value': 'korean_war',
        },
        {
          'id': 'KxxgZJ',
          'display_name': 'Pre-Korean (1/1/1947-6/27/1950)',
          'value': 'pre_korean',
        },
        {
          'id': 'jnq+b0',
          'display_name': 'World War 2 (12/7/1941-12/31/1946)',
          'value': 'ww2',
        },
        {
          'id': 'sPw/Cy',
          'display_name': 'Merchant Marine (12/7/1941-8/15/1945)',
          'value': 'merchant_marine',
        },
        {
          'id': 'P22sfT',
          'display_name': 'World War 1 (4/6/1917-11/11/1918)',
          'value': 'ww1',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'deployed': [
        {
          'id': 'V9M1BS',
          'display_name': 'Yes',
          'value': 'yes',
        },
        {
          'id': '120scI',
          'display_name': 'No',
          'value': 'no',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'discharge_type': [
        {
          'id': 'DgfUIl',
          'display_name': 'Honorable',
          'value': 'honorable',
        },
        {
          'id': '8+TsJF',
          'display_name': 'General',
          'value': 'general',
        },
        {
          'id': 'PMPpzp',
          'display_name': 'Other than Honorable',
          'value': 'other_than_honorable',
        },
        {
          'id': 'IFuOVl',
          'display_name': 'Bad Conduct',
          'value': 'bad_conduct',
        },
        {
          'id': '4u8S9l',
          'display_name': 'Dishonorable',
          'value': 'dishonorable',
        },
        {
          'id': '0QFTEu',
          'display_name': 'Uncharacterized',
          'value': 'uncharacterized',
        },
        {
          'id': '4VVcAJ',
          'display_name': 'Other',
          'value': 'other',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'discharge_disability': [
        {
          'id': 'V9M1BS',
          'display_name': 'Yes',
          'value': 'yes',
        },
        {
          'id': '120scI',
          'display_name': 'No',
          'value': 'no',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'connected_disability': [
        {
          'id': 'V9M1BS',
          'display_name': 'Yes',
          'value': 'yes',
        },
        {
          'id': '120scI',
          'display_name': 'No',
          'value': 'no',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'disability_rating': [
        {
          'display_name': '0%',
          'value': '0',
        },
        {
          'display_name': '10%',
          'value': '10',
        },
        {
          'display_name': '20%',
          'value': '20',
        },
        {
          'display_name': '30%',
          'value': '30',
        },
        {
          'display_name': '40%',
          'value': '40',
        },
        {
          'display_name': '50%',
          'value': '50',
        },
        {
          'display_name': '60%',
          'value': '60',
        },
        {
          'display_name': '70%',
          'value': '70',
        },
        {
          'display_name': '80%',
          'value': '80',
        },
        {
          'display_name': '90%',
          'value': '90',
        },
        {
          'display_name': '100%',
          'value': '100',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'proof_of_veteran_status': [
        {
          'id': 'V9M1BS',
          'display_name': 'Yes',
          'value': 'yes',
        },
        {
          'id': '120scI',
          'display_name': 'No',
          'value': 'no',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
      'proof_type': [
        {
          'display_name': 'DD214',
          'value': 'dd214',
        },
        {
          'display_name': 'VA ID',
          'value': 'va_id',
        },
        {
          'id': '+kSBkS',
          'display_name': 'Statement of Service',
          'value': 'statement_of_service',
        },
        {
          'id': 'brWR5L',
          'display_name': 'Retiree ID',
          'value': 'retiree_id',
        },
        {
          'id': 't1T8/K',
          'display_name': 'Drivers License',
          'value': 'drivers_license',
        },
        {
          'id': 'fW8/I1',
          'display_name': 'Military ID',
          'value': 'military_id',
        },
        {
          'id': '4VVcAJ',
          'display_name': 'Other',
          'value': 'other',
        },
        {
          'id': '5lO6vB',
          'display_name': 'Prefer Not to Disclose',
          'value': 'prefer_not_to_disclose',
        },
      ],
    },
    'box': {
      'attachment_types': [
        {
          'display_name': '',
          'value': '',
        },
        {
          'display_name': 'Assistance Request',
          'value': 'assistance_request',
        },
        {
          'display_name': 'Client',
          'value': 'client',
        },
        {
          'display_name': 'Consent Request',
          'value': 'consent_request',
        },
        {
          'display_name': 'Referral',
          'value': 'referral',
        },
        {
          'display_name': 'Service Case',
          'value': 'service_case',
        },
      ],
      'endpoints': [
        {
          'display_name': 'Consent',
          'value': 'v1/consent',
        },
        {
          'display_name': 'Document',
          'value': 'v1/documents',
        },
      ],
    },
    'oral_communication_types': [
      {
        'display_name': 'Call',
        'value': 'phone_call',
      },
    ],
    'written_communication_types': [
      {
        'display_name': 'Message',
        'value': 'message',
      },
      {
        'display_name': 'Notification',
        'value': 'notification',
      },
    ],
    'external_identifiers': {
      'insurance_types': [
        {
          'display_name': 'Medicaid',
          'value': 'medicaid',
        },
        {
          'display_name': 'Medicare',
          'value': 'medicare',
        },
      ],
      'source_types': [
        {
          'display_name': 'EMR',
          'value': 'emr',
        },
      ],
    },
  },
  'people': {
    'manifest': {
      'citizenship': [
        {
          'display_name': 'US Citizen',
          'value': 'CITIZENSHIP_US_CITIZEN',
        },
        {
          'display_name': 'US National',
          'value': 'CITIZENSHIP_US_NATIONAL',
        },
        {
          'display_name': 'Lawful Permanent Resident',
          'value': 'CITIZENSHIP_LAWFUL_PERMANENT_RESIDENT',
        },
        {
          'display_name': 'Other',
          'value': 'CITIZENSHIP_OTHER',
        },
        {
          'display_name': 'Undisclosed',
          'value': 'CITIZENSHIP_UNDISCLOSED',
        },
      ],
      'ethnicity': [
        {
          'display_name': 'Hispanic or Latino',
          'value': 'ETHNICITY_HISPANIC_OR_LATINO',
        },
        {
          'display_name': 'Not Hispanic or Latino',
          'value': 'ETHNICITY_NOT_HISPANIC_OR_LATINO',
        },
        {
          'display_name': 'Undisclosed',
          'value': 'ETHNICITY_UNDISCLOSED',
        },
      ],
      'genders': [
        {
          'display_name': 'Male',
          'value': 'GENDER_MALE',
        },
        {
          'display_name': 'Female',
          'value': 'GENDER_FEMALE',
        },
        {
          'display_name': 'Undisclosed',
          'value': 'GENDER_UNDISCLOSED',
        },
        {
          'display_name': 'Non-Binary',
          'value': 'GENDER_NON_BINARY',
        },
        {
          'display_name': 'Other',
          'value': 'GENDER_OTHER',
        },
      ],
      'marital_status': [
        {
          'display_name': 'Divorced',
          'value': 'MARITAL_STATUS_DIVORCED',
        },
        {
          'display_name': 'Domestic Partner',
          'value': 'MARITAL_STATUS_DOMESTIC_PARTNER',
        },
        {
          'display_name': 'Married/Civil Union',
          'value': 'MARITAL_STATUS_MARRIED_CIVIL_UNION',
        },
        {
          'display_name': 'Single/Never Married',
          'value': 'MARITAL_STATUS_SINGLE',
        },
        {
          'display_name': 'Separated',
          'value': 'MARITAL_STATUS_SEPARATED',
        },
        {
          'display_name': 'Undisclosed',
          'value': 'MARITAL_STATUS_UNDISCLOSED',
        },
        {
          'display_name': 'Widowed',
          'value': 'MARITAL_STATUS_WIDOWED',
        },
      ],
      'race': [
        {
          'display_name': 'American Indian or Alaska Native',
          'value': 'RACE_AMERICAN_INDIAN',
        },
        {
          'display_name': 'Asian',
          'value': 'RACE_ASIAN',
        },
        {
          'display_name': 'Black/African American',
          'value': 'RACE_BLACK_AFRICAN_AMERICAN',
        },
        {
          'display_name': 'Native Hawaiian or Pacific Islander',
          'value': 'RACE_HAWAIIAN',
        },
        {
          'display_name': 'Other Race',
          'value': 'RACE_OTHER',
        },
        {
          'display_name': 'Undisclosed',
          'value': 'RACE_UNDISCLOSED',
        },
        {
          'display_name': 'White',
          'value': 'RACE_WHITE',
        },
      ],
      'client_types': [
        {
          'display_name': 'contact',
          'value': 'CLIENT_TYPE_CONTACT',
        },
        {
          'display_name': 'assistance_requestor',
          'value': 'CLIENT_TYPE_ASSISTANCE_REQUESTOR',
        },
      ],
    },
    'titles': [
      {
        'id': '3cQbgO',
        'display_name': 'Mr.',
        'value': 'Mr.',
      },
      {
        'id': 'cfdrSW',
        'display_name': 'Miss',
        'value': 'Miss',
      },
      {
        'id': 'byuFpc',
        'display_name': 'Mrs.',
        'value': 'Mrs.',
      },
      {
        'id': 'oYppoe',
        'display_name': 'Ms.',
        'value': 'Ms.',
      },
      {
        'id': 'z7A5JY',
        'display_name': 'Dr.',
        'value': 'Dr.',
      },
    ],
    'suffix': [
      {
        'id': 'KbWe66',
        'display_name': 'Jr.',
        'value': 'Jr.',
      },
      {
        'id': '/cNBkO',
        'display_name': 'Sr.',
        'value': 'Sr.',
      },
      {
        'id': 'hbxr+P',
        'display_name': 'III',
        'value': 'III',
      },
      {
        'id': 'FBbopG',
        'display_name': 'IV',
        'value': 'IV',
      },
      {
        'id': '33WoEM',
        'display_name': 'V',
        'value': 'V',
      },
      {
        'id': 'BUWYLV',
        'display_name': 'Esq.',
        'value': 'Esq.',
      },
      {
        'id': 'mWGnk8',
        'display_name': 'Ph.D',
        'value': 'Ph.D',
      },
    ],
    'citizenship': [
      {
        'id': 'tyA0P0',
        'display_name': 'US Citizen',
        'value': 'us-citizen',
      },
      {
        'id': 'WcCxDK',
        'display_name': 'US National',
        'value': 'us-national',
      },
      {
        'id': 'YFX6OU',
        'display_name': 'Lawful Permanent Resident',
        'value': 'lawful-permanent-resident',
      },
      {
        'id': '4VVcAJ',
        'display_name': 'Other',
        'value': 'other',
      },
      {
        'id': 'R/hS/l',
        'display_name': 'Undisclosed',
        'value': 'undisclosed',
      },
    ],
    'ethnicity': [
      {
        'id': '++qzM1',
        'display_name': 'Hispanic or Latino',
        'value': 'hispanic-or-latino',
      },
      {
        'id': 'dAeuLJ',
        'display_name': 'Not Hispanic or Latino',
        'value': 'not-hispanic-or-latino',
      },
      {
        'id': 'R/hS/l',
        'display_name': 'Undisclosed',
        'value': 'undisclosed',
      },
    ],
    'sexual_orientations': [
      {
        'display_name': 'Straight or heterosexual',
        'value': 'straight-or-heterosexual'
      },
      {
        'display_name': 'Lesbian',
        'value': 'lesbian'
      },
      {
        'display_name': 'Gay',
        'value': 'gay'
      },
      {
        'display_name': 'Bisexual',
        'value': 'bisexual'
      },
      {
        'display_name': 'Pansexual',
        'value': 'pansexual'
      },
      {
        'display_name': 'Queer',
        'value': 'queer'
      },
      {
        'display_name': 'Asexual',
        'value': 'asexual'
      },
      {
        'display_name': 'Questioning/Unsure',
        'value': 'questioning/unsure'
      },
      {
        'display_name': 'Other',
        'value': 'other'
      },
      {
        'display_name': 'Choose not to disclose',
        'value': 'choose-not-to-disclose'
      }
    ],
    'genders': [
      {
        'id': 'TVsAG1',
        'display_name': 'Male',
        'value': 'male',
      },
      {
        'id': '5487nh',
        'display_name': 'Female',
        'value': 'female',
      },
      {
        'id': 'R/hS/l',
        'display_name': 'Undisclosed',
        'value': 'undisclosed',
      },
      {
        'id': 'BX4esw',
        'display_name': 'Non-Binary',
        'value': 'non-binary',
      },
      {
        'id': 'jix81R',
        'display_name': 'Trans Man',
        'value': 'trans-man',
      },
      {
        'id': 'ibG5zf',
        'display_name': 'Trans Woman',
        'value': 'trans-woman',
      },
      {
        'id': '4VVcAJ',
        'display_name': 'Other',
        'value': 'other',
      },
    ],
    'marital_status': [
      {
        'id': 'vst/Ow',
        'display_name': 'Divorced',
        'value': 'divorced',
      },
      {
        'id': 'RosUst',
        'display_name': 'Domestic Partner',
        'value': 'domestic_partner',
      },
      {
        'id': '1EGhws',
        'display_name': 'Married/Civil Union',
        'value': 'married-civil-union',
      },
      {
        'id': '7FwT7v',
        'display_name': 'Single/Never Married',
        'value': 'single/never-married',
      },
      {
        'id': 'PqNJq3',
        'display_name': 'Separated',
        'value': 'separated',
      },
      {
        'id': 'R/hS/l',
        'display_name': 'Undisclosed',
        'value': 'undisclosed',
      },
      {
        'id': 'NI0Ip3',
        'display_name': 'Widowed',
        'value': 'widowed',
      },
    ],
    'race': [
      {
        'id': 'BOqie7',
        'display_name': 'American Indian or Alaska Native',
        'value': 'american-indian-alaska-native',
      },
      {
        'id': 'WFqEbw',
        'display_name': 'Asian',
        'value': 'asian',
      },
      {
        'id': 'Sn/bY8',
        'display_name': 'Black/African American',
        'value': 'black-african-american',
      },
      {
        'id': 'GLUsIh',
        'display_name': 'Native Hawaiian or Pacific Islander',
        'value': 'native-hawaiian-or-pacific-islander',
      },
      {
        'id': 'wNTE4V',
        'display_name': 'Other Race',
        'value': 'other-race',
      },
      {
        'id': 'R/hS/l',
        'display_name': 'Undisclosed',
        'value': 'undisclosed',
      },
      {
        'id': 'rjxXgn',
        'display_name': 'White',
        'value': 'white',
      },
    ],
  },
  'assistance_requests': {
    'statuses': [
      {
        'display_name': 'Needs Action',
        'value': 'pending',
      },
      {
        'display_name': 'In Review',
        'value': 'in_review',
      },
      {
        'display_name': 'Accepted',
        'value': 'accepted',
      },
      {
        'display_name': 'Closed',
        'value': 'closed',
      },
      {
        'display_name': 'Archived',
        'value': 'archived',
      },
      {
        'display_name': 'Processed',
        'value': 'processed',
      },
    ],
    'address_types': [
      {
        'display_name': 'Full Address',
        'value': 'full',
      },
      {
        'display_name': 'City & State Only',
        'value': 'city_state',
      },
      {
        'display_name': 'Postal Code Only',
        'value': 'postal_code',
      },
      {
        'display_name': 'City, State, and Postal Code Only',
        'value': 'city_state_postal_code',
      },
    ],
  },
  'exports': {
    'types': [
      {
        'display_name': 'Assessments',
        'value': 'assessment',
      },
      {
        'display_name': 'Clients',
        'value': 'contact',
      },
      {
        'display_name': 'Notes',
        'value': 'interaction',
      },
      {
        'display_name': 'Referrals',
        'value': 'referral',
      },
      {
        'display_name': 'Cases',
        'value': 'service_case',
      },
      {
        'display_name': 'Service Episodes (Raw)',
        'value': 'service_episode_raw',
      },
      {
        'display_name': 'Service Episodes (Snapshot)',
        'value': 'service_episode_snapshot',
      },
      {
        'display_name': 'Users',
        'value': 'user',
      },
    ],
    'statuses': [
      {
        'display_name': 'Pending',
        'value': 'pending',
      },
      {
        'display_name': 'Working',
        'value': 'working',
      },
      {
        'display_name': 'Completed',
        'value': 'completed',
      },
      {
        'display_name': 'Canceled',
        'value': 'canceled',
      },
      {
        'display_name': 'Failed',
        'value': 'failed',
      },
    ],
    'time_fields': [
      {
        'display_name': 'Created',
        'value': 'created_at',
      },
      {
        'display_name': 'Updated',
        'value': 'updated_at',
      },
      {
        'display_name': 'Created or Updated',
        'value': 'created_or_updated',
      },
    ],
    'time_periods': [
      {
        'display_name': 'Last 7 Days',
        'value': 'last_7',
      },
      {
        'display_name': 'Last 30 Days',
        'value': 'last_30',
      },
      {
        'display_name': 'Last 60 Days',
        'value': 'last_60',
      },
      {
        'display_name': 'Last 90 Days',
        'value': 'last_90',
      },
      {
        'display_name': 'All Time',
        'value': 'all_time',
      },
      {
        'display_name': 'Custom Date Range',
        'value': 'custom',
      },
    ],
    'target_types': [
      {
        'display_name': 'Networks',
        'value': 'network',
      },
      {
        'display_name': 'Providers',
        'value': 'provider',
      },
    ],
  },
  'imports': {
    'statuses': [
      {
        'display_name': 'Pending',
        'value': 'pending',
      },
      {
        'display_name': 'Working',
        'value': 'working',
      },
      {
        'display_name': 'Completed',
        'value': 'completed',
      },
      {
        'display_name': 'Failed',
        'value': 'failed',
      },
    ],
  },
  'groups': {
    'networks': {
      'categorization': [
        {
          'display_name': 'In Network',
          'value': 'in_network',
        },
        {
          'display_name': 'Out of Network',
          'value': 'out_of_network',
        },
      ],
    },
    'programs': {
      'eligibility': {
        'types': [
          {
            'display_name': 'integer',
            'value': 'integer',
          },
          {
            'display_name': 'string',
            'value': 'string',
          },
          {
            'display_name': 'boolean',
            'value': 'boolean',
          },
        ],
        'operators': [
          {
            'display_name': 'lt',
            'value': 'lt',
          },
          {
            'display_name': 'gt',
            'value': 'gt',
          },
          {
            'display_name': 'eq',
            'value': 'eq',
          },
        ],
      },
      'delivery_options': [
        {
          'display_name': 'In Home',
          'value': 'in_home',
        },
        {
          'display_name': 'In Office',
          'value': 'in_office',
        },
        {
          'display_name': 'Web Based',
          'value': 'web_based',
        },
        {
          'display_name': 'Phone Based',
          'value': 'phone_based',
        },
      ],
      'accessibility_options': [
        {
          'display_name': 'ADA accessible',
          'value': 'ada_accessible',
        },
        {
          'display_name': 'Blind accommodation',
          'value': 'blind_accomodation',
        },
        {
          'display_name': 'Deaf and hard of hearing accommodation',
          'value': 'deaf_or_hard_of_hearing',
        },
        {
          'display_name': 'Interpreter/translation services available',
          'value': 'translation_services',
        },
      ],
      'payment_options': [
        {
          'display_name': 'Free',
          'value': 'free',
        },
        {
          'display_name': 'Insurance',
          'value': 'insurance',
        },
        {
          'display_name': 'Self-pay',
          'value': 'self_pay',
        },
        {
          'display_name': 'Sliding Scale',
          'value': 'sliding_scale',
        },
      ],
      'transportation_options': [
        {
          'display_name': 'Free transportation offered',
          'value': 'free_transportation',
        },
      ],
      'population': [
        {
          'display_name': 'Adolescents (13-19 years)',
          'value': 'adolescents',
        },
        {
          'display_name': 'Caregivers (Non-paternal)',
          'value': 'caregivers',
        },
        {
          'display_name': 'Children (4-12 years)',
          'value': 'children',
        },
        {
          'display_name': 'Domestic violence survivors',
          'value': 'domestic_violence_survivors',
        },
        {
          'display_name': 'Homebound',
          'value': 'homebound',
        },
        {
          'display_name': 'Homeless',
          'value': 'homeless',
        },
        {
          'display_name': 'Immigrants and refugees',
          'value': 'immigrants_and_refugees',
        },
        {
          'display_name': 'Infants and toddlers (0-3 years)',
          'value': 'infants_and_toddlers',
        },
        {
          'display_name': 'Justice-involved',
          'value': 'justice_involved',
        },
        {
          'display_name': 'LGBTQ+',
          'value': 'lgbtq_plus',
        },
        {
          'display_name': 'Low-income individuals and households',
          'value': 'low_income_individuals_and_households',
        },
        {
          'display_name': 'Native Americans or Alaska Natives',
          'value': 'native_americans_or_alaska_natives',
        },
        {
          'display_name': 'People with cognitive disabilities',
          'value': 'people_with_cognitive_disabilities',
        },
        {
          'display_name': 'People with developmental disabilities',
          'value': 'people_with_developmental_disabilities',
        },
        {
          'display_name': 'People with HIV/AIDS',
          'value': 'people_with_hiv_aids',
        },
        {
          'display_name': 'People with mental health issues',
          'value': 'people_with_mental_health_issues',
        },
        {
          'display_name': 'People with physical disabilities',
          'value': 'people_with_physical_disabilities',
        },
        {
          'display_name': 'People with substance use issues',
          'value': 'people_with_substance_abuse_issues',
        },
        {
          'display_name': 'Pregnancy',
          'value': 'pregnancy',
        },
        {
          'display_name': 'Registered sex offenders',
          'value': 'registered_sex_offenders',
        },
        {
          'display_name': 'Seniors',
          'value': 'seniors',
        },
        {
          'display_name': 'Sexual assault survivors',
          'value': 'sexual_assault_survivors',
        },
        {
          'display_name': 'Veterans and military families',
          'value': 'veterans_and_military_families',
        },
        {
          'display_name': 'Victims of natural disaster',
          'value': 'victims_of_natural_disaster',
        },
        {
          'display_name': 'Victims of crime',
          'value': 'victims_of_crime',
        },
        {
          'display_name': 'Unemployed',
          'value': 'unemployed',
        },
      ],
    },
    'group_facade': {
      'group_type_filter': [
        {
          'display_name': 'Groups',
          'value': 0,
        },
        {
          'display_name': 'Address Book Groups',
          'value': 1,
        },
        {
          'display_name': 'All',
          'value': 2,
        },
        {
          'display_name': 'Exclude Address Book Groups',
          'value': 3,
        },
      ],
    },
  },
  'roles': {
    'role_keys': [
      {
        'display_name': 'Network Access User',
        'value': 'network_access_user',
      },
    ],
  },
  'screenings': {
    'statuses': [
      {
        'display_name': 'Not Started',
        'value': 'not_started',
      },
      {
        'display_name': 'In Progress',
        'value': 'in_progress',
      },
      {
        'display_name': 'Completed',
        'value': 'completed',
      },
      {
        'display_name': 'Exempted',
        'value': 'exempted',
      },
    ],
    'risk_levels': [
      {
        'display_name': 'High Risk',
        'value': 'high_risk',
      },
      {
        'display_name': 'Low Risk',
        'value': 'low_risk',
      },
      {
        'display_name': 'No Risk',
        'value': 'no_risk',
      },
    ],
    'listing_options': [
      {
        'display_name': 'Always',
        'value': 'always',
      },
      {
        'display_name': 'Never',
        'value': 'never',
      },
      {
        'display_name': 'Low Risk Only',
        'value': 'low_risk_only',
      },
    ],
    'navigation_options': [
      {
        'display_name': 'Always',
        'value': 'always',
      },
      {
        'display_name': 'Never',
        'value': 'never',
      },
      {
        'display_name': 'High Risk Only',
        'value': 'high_risk_only',
      },
    ],
    'question_types': [
      {
        'display_name': 'Single Choice',
        'value': 'single-choice',
      },
      {
        'display_name': 'Multiple Choice',
        'value': 'multiple-choice',
      },
      {
        'display_name': 'Aggregate',
        'value': 'aggregate',
      },
    ],
  },
  'secure': {
    'role_types': [
      {
        'display_name': 'program',
        'value': 'program',
      },
      {
        'display_name': 'system',
        'value': 'system',
      },
      {
        'display_name': 'restricted',
        'value': 'restricted',
      },
    ],
  },
  'service_types': {
    'access_levels': [
      {
        'display_name': 'Regular',
        'value': 'regular',
      },
      {
        'display_name': 'Sensitive',
        'value': 'sensitive',
      },
    ],
    'outcomes': {
      'resolution_types': [
        {
          'display_name': 'resolved',
          'value': 'resolved',
        },
        {
          'display_name': 'not_resolved',
          'value': 'not_resolved',
        },
      ],
      'allowed_types': [
        {
          'display_name': 'Cases',
          'value': 'cases',
        },
        {
          'display_name': 'Referrals',
          'value': 'referrals',
        },
        {
          'display_name': 'Assistance Requests',
          'value': 'assistance_requests',
        },
      ],
    },
  },
  'address_books': {
    'group_statuses': [
      {
        'display_name': 'Draft',
        'value': 'draft',
      },
      {
        'display_name': 'In Review',
        'value': 'in_review',
      },
      {
        'display_name': 'Published',
        'value': 'published',
      },
      {
        'display_name': 'Archived',
        'value': 'archived',
      },
    ],
  },
  'group_data': {
    'address_types': [
      {
        'display_name': 'Work',
        'value': 'work',
      },
      {
        'display_name': 'Unknown',
        'value': 'unknown',
      },
    ],
  },
  'preferences': {
    'trinary': [
      {
        'display_name': 'On',
        'value': 'on',
      },
      {
        'display_name': 'Remind Once',
        'value': 'remind_once',
      },
      {
        'display_name': 'Off',
        'value': 'off',
      },
    ],
  },
  'reports': {
    'display_names': {
      'referrals': [
        {
          'display_name': 'Accepted',
          'value': 'Accepted',
        },
        {
          'display_name': 'Rejected',
          'value': 'Rejected',
        },
        {
          'display_name': 'Rejected',
          'value': 'Rejected',
        },
        {
          'display_name': 'In Review',
          'value': 'In Review',
        },
        {
          'display_name': 'Needs Action',
          'value': 'Needs Action',
        },
        {
          'display_name': 'Draft',
          'value': 'Draft',
        },
      ],
      'cases': [
        {
          'display_name': 'Resolved',
          'value': 'Resolved',
        },
        {
          'display_name': 'Unresolved',
          'value': 'Unresolved',
        },
      ],
      'service_episode_network_scopes': [
        {
          'display_name': 'Sent and Serviced in my network',
          'value': 'Sent and Serviced in my network',
        },
        {
          'display_name': 'Sent by my network',
          'value': 'Sent by my network',
        },
        {
          'display_name': 'Serviced by my network',
          'value': 'Serviced by my network',
        },
      ],
    },
  },
  'geography': {
    'countries': [
      {
        'display_name': 'Andorra',
        'value': 'AD',
      },
      {
        'display_name': 'United Arab Emirates',
        'value': 'AE',
      },
      {
        'display_name': 'Afghanistan',
        'value': 'AF',
      },
      {
        'display_name': 'Antigua and Barbuda',
        'value': 'AG',
      },
      {
        'display_name': 'Anguilla',
        'value': 'AI',
      },
      {
        'display_name': 'Albania',
        'value': 'AL',
      },
      {
        'display_name': 'Armenia',
        'value': 'AM',
      },
      {
        'display_name': 'Angola',
        'value': 'AO',
      },
      {
        'display_name': 'Antarctica',
        'value': 'AQ',
      },
      {
        'display_name': 'Argentina',
        'value': 'AR',
      },
      {
        'display_name': 'American Samoa',
        'value': 'AS',
      },
      {
        'display_name': 'Austria',
        'value': 'AT',
      },
      {
        'display_name': 'Australia',
        'value': 'AU',
      },
      {
        'display_name': 'Aruba',
        'value': 'AW',
      },
      {
        'display_name': 'Åland Islands',
        'value': 'AX',
      },
      {
        'display_name': 'Azerbaijan',
        'value': 'AZ',
      },
      {
        'display_name': 'Bosnia and Herzegovina',
        'value': 'BA',
      },
      {
        'display_name': 'Barbados',
        'value': 'BB',
      },
      {
        'display_name': 'Bangladesh',
        'value': 'BD',
      },
      {
        'display_name': 'Belgium',
        'value': 'BE',
      },
      {
        'display_name': 'Burkina Faso',
        'value': 'BF',
      },
      {
        'display_name': 'Bulgaria',
        'value': 'BG',
      },
      {
        'display_name': 'Bahrain',
        'value': 'BH',
      },
      {
        'display_name': 'Burundi',
        'value': 'BI',
      },
      {
        'display_name': 'Benin',
        'value': 'BJ',
      },
      {
        'display_name': 'Saint Barthélemy',
        'value': 'BL',
      },
      {
        'display_name': 'Bermuda',
        'value': 'BM',
      },
      {
        'display_name': 'Brunei Darussalam',
        'value': 'BN',
      },
      {
        'display_name': 'Bolivia, Plurinational State of',
        'value': 'BO',
      },
      {
        'display_name': 'Bonaire, Sint Eustatius and Saba',
        'value': 'BQ',
      },
      {
        'display_name': 'Brazil',
        'value': 'BR',
      },
      {
        'display_name': 'Bahamas',
        'value': 'BS',
      },
      {
        'display_name': 'Bhutan',
        'value': 'BT',
      },
      {
        'display_name': 'Bouvet Island',
        'value': 'BV',
      },
      {
        'display_name': 'Botswana',
        'value': 'BW',
      },
      {
        'display_name': 'Belarus',
        'value': 'BY',
      },
      {
        'display_name': 'Belize',
        'value': 'BZ',
      },
      {
        'display_name': 'Canada',
        'value': 'CA',
      },
      {
        'display_name': 'Cocos (Keeling) Islands',
        'value': 'CC',
      },
      {
        'display_name': 'Congo, The Democratic Republic of the',
        'value': 'CD',
      },
      {
        'display_name': 'Central African Republic',
        'value': 'CF',
      },
      {
        'display_name': 'Congo',
        'value': 'CG',
      },
      {
        'display_name': 'Switzerland',
        'value': 'CH',
      },
      {
        'display_name': "Côte d'Ivoire",
        'value': 'CI',
      },
      {
        'display_name': 'Cook Islands',
        'value': 'CK',
      },
      {
        'display_name': 'Chile',
        'value': 'CL',
      },
      {
        'display_name': 'Cameroon',
        'value': 'CM',
      },
      {
        'display_name': 'China',
        'value': 'CN',
      },
      {
        'display_name': 'Colombia',
        'value': 'CO',
      },
      {
        'display_name': 'Costa Rica',
        'value': 'CR',
      },
      {
        'display_name': 'Cuba',
        'value': 'CU',
      },
      {
        'display_name': 'Cape Verde',
        'value': 'CV',
      },
      {
        'display_name': 'Curaçao',
        'value': 'CW',
      },
      {
        'display_name': 'Christmas Island',
        'value': 'CX',
      },
      {
        'display_name': 'Cyprus',
        'value': 'CY',
      },
      {
        'display_name': 'Czech Republic',
        'value': 'CZ',
      },
      {
        'display_name': 'Germany',
        'value': 'DE',
      },
      {
        'display_name': 'Djibouti',
        'value': 'DJ',
      },
      {
        'display_name': 'Denmark',
        'value': 'DK',
      },
      {
        'display_name': 'Dominica',
        'value': 'DM',
      },
      {
        'display_name': 'Dominican Republic',
        'value': 'DO',
      },
      {
        'display_name': 'Algeria',
        'value': 'DZ',
      },
      {
        'display_name': 'Ecuador',
        'value': 'EC',
      },
      {
        'display_name': 'Estonia',
        'value': 'EE',
      },
      {
        'display_name': 'Egypt',
        'value': 'EG',
      },
      {
        'display_name': 'Western Sahara',
        'value': 'EH',
      },
      {
        'display_name': 'Eritrea',
        'value': 'ER',
      },
      {
        'display_name': 'Spain',
        'value': 'ES',
      },
      {
        'display_name': 'Ethiopia',
        'value': 'ET',
      },
      {
        'display_name': 'Finland',
        'value': 'FI',
      },
      {
        'display_name': 'Fiji',
        'value': 'FJ',
      },
      {
        'display_name': 'Falkland Islands (Malvinas)',
        'value': 'FK',
      },
      {
        'display_name': 'Micronesia, Federated States of',
        'value': 'FM',
      },
      {
        'display_name': 'Faroe Islands',
        'value': 'FO',
      },
      {
        'display_name': 'France',
        'value': 'FR',
      },
      {
        'display_name': 'Gabon',
        'value': 'GA',
      },
      {
        'display_name': 'United Kingdom',
        'value': 'GB',
      },
      {
        'display_name': 'Grenada',
        'value': 'GD',
      },
      {
        'display_name': 'Georgia',
        'value': 'GE',
      },
      {
        'display_name': 'French Guiana',
        'value': 'GF',
      },
      {
        'display_name': 'Guernsey',
        'value': 'GG',
      },
      {
        'display_name': 'Ghana',
        'value': 'GH',
      },
      {
        'display_name': 'Gibraltar',
        'value': 'GI',
      },
      {
        'display_name': 'Greenland',
        'value': 'GL',
      },
      {
        'display_name': 'Gambia',
        'value': 'GM',
      },
      {
        'display_name': 'Guinea',
        'value': 'GN',
      },
      {
        'display_name': 'Guadeloupe',
        'value': 'GP',
      },
      {
        'display_name': 'Equatorial Guinea',
        'value': 'GQ',
      },
      {
        'display_name': 'Greece',
        'value': 'GR',
      },
      {
        'display_name': 'South Georgia and the South Sandwich Islands',
        'value': 'GS',
      },
      {
        'display_name': 'Guatemala',
        'value': 'GT',
      },
      {
        'display_name': 'Guam',
        'value': 'GU',
      },
      {
        'display_name': 'Guinea-Bissau',
        'value': 'GW',
      },
      {
        'display_name': 'Guyana',
        'value': 'GY',
      },
      {
        'display_name': 'Hong Kong',
        'value': 'HK',
      },
      {
        'display_name': 'Heard Island and McDonald Islands',
        'value': 'HM',
      },
      {
        'display_name': 'Honduras',
        'value': 'HN',
      },
      {
        'display_name': 'Croatia',
        'value': 'HR',
      },
      {
        'display_name': 'Haiti',
        'value': 'HT',
      },
      {
        'display_name': 'Hungary',
        'value': 'HU',
      },
      {
        'display_name': 'Indonesia',
        'value': 'ID',
      },
      {
        'display_name': 'Ireland',
        'value': 'IE',
      },
      {
        'display_name': 'Israel',
        'value': 'IL',
      },
      {
        'display_name': 'Isle of Man',
        'value': 'IM',
      },
      {
        'display_name': 'India',
        'value': 'IN',
      },
      {
        'display_name': 'British Indian Ocean Territory',
        'value': 'IO',
      },
      {
        'display_name': 'Iraq',
        'value': 'IQ',
      },
      {
        'display_name': 'Iran, Islamic Republic of',
        'value': 'IR',
      },
      {
        'display_name': 'Iceland',
        'value': 'IS',
      },
      {
        'display_name': 'Italy',
        'value': 'IT',
      },
      {
        'display_name': 'Jersey',
        'value': 'JE',
      },
      {
        'display_name': 'Jamaica',
        'value': 'JM',
      },
      {
        'display_name': 'Jordan',
        'value': 'JO',
      },
      {
        'display_name': 'Japan',
        'value': 'JP',
      },
      {
        'display_name': 'Kenya',
        'value': 'KE',
      },
      {
        'display_name': 'Kyrgyzstan',
        'value': 'KG',
      },
      {
        'display_name': 'Cambodia',
        'value': 'KH',
      },
      {
        'display_name': 'Kiribati',
        'value': 'KI',
      },
      {
        'display_name': 'Comoros',
        'value': 'KM',
      },
      {
        'display_name': 'Saint Kitts and Nevis',
        'value': 'KN',
      },
      {
        'display_name': "Korea, Democratic People's Republic of",
        'value': 'KP',
      },
      {
        'display_name': 'Korea, Republic of',
        'value': 'KR',
      },
      {
        'display_name': 'Kuwait',
        'value': 'KW',
      },
      {
        'display_name': 'Cayman Islands',
        'value': 'KY',
      },
      {
        'display_name': 'Kazakhstan',
        'value': 'KZ',
      },
      {
        'display_name': "Lao People's Democratic Republic",
        'value': 'LA',
      },
      {
        'display_name': 'Lebanon',
        'value': 'LB',
      },
      {
        'display_name': 'Saint Lucia',
        'value': 'LC',
      },
      {
        'display_name': 'Liechtenstein',
        'value': 'LI',
      },
      {
        'display_name': 'Sri Lanka',
        'value': 'LK',
      },
      {
        'display_name': 'Liberia',
        'value': 'LR',
      },
      {
        'display_name': 'Lesotho',
        'value': 'LS',
      },
      {
        'display_name': 'Lithuania',
        'value': 'LT',
      },
      {
        'display_name': 'Luxembourg',
        'value': 'LU',
      },
      {
        'display_name': 'Latvia',
        'value': 'LV',
      },
      {
        'display_name': 'Libya',
        'value': 'LY',
      },
      {
        'display_name': 'Morocco',
        'value': 'MA',
      },
      {
        'display_name': 'Monaco',
        'value': 'MC',
      },
      {
        'display_name': 'Moldova, Republic of',
        'value': 'MD',
      },
      {
        'display_name': 'Montenegro',
        'value': 'ME',
      },
      {
        'display_name': 'Saint Martin (French part)',
        'value': 'MF',
      },
      {
        'display_name': 'Madagascar',
        'value': 'MG',
      },
      {
        'display_name': 'Marshall Islands',
        'value': 'MH',
      },
      {
        'display_name': 'Macedonia, Republic of',
        'value': 'MK',
      },
      {
        'display_name': 'Mali',
        'value': 'ML',
      },
      {
        'display_name': 'Myanmar',
        'value': 'MM',
      },
      {
        'display_name': 'Mongolia',
        'value': 'MN',
      },
      {
        'display_name': 'Macao',
        'value': 'MO',
      },
      {
        'display_name': 'Northern Mariana Islands',
        'value': 'MP',
      },
      {
        'display_name': 'Martinique',
        'value': 'MQ',
      },
      {
        'display_name': 'Mauritania',
        'value': 'MR',
      },
      {
        'display_name': 'Montserrat',
        'value': 'MS',
      },
      {
        'display_name': 'Malta',
        'value': 'MT',
      },
      {
        'display_name': 'Mauritius',
        'value': 'MU',
      },
      {
        'display_name': 'Maldives',
        'value': 'MV',
      },
      {
        'display_name': 'Malawi',
        'value': 'MW',
      },
      {
        'display_name': 'Mexico',
        'value': 'MX',
      },
      {
        'display_name': 'Malaysia',
        'value': 'MY',
      },
      {
        'display_name': 'Mozambique',
        'value': 'MZ',
      },
      {
        'display_name': 'Namibia',
        'value': 'NA',
      },
      {
        'display_name': 'New Caledonia',
        'value': 'NC',
      },
      {
        'display_name': 'Niger',
        'value': 'NE',
      },
      {
        'display_name': 'Norfolk Island',
        'value': 'NF',
      },
      {
        'display_name': 'Nigeria',
        'value': 'NG',
      },
      {
        'display_name': 'Nicaragua',
        'value': 'NI',
      },
      {
        'display_name': 'Netherlands',
        'value': 'NL',
      },
      {
        'display_name': 'Norway',
        'value': 'NO',
      },
      {
        'display_name': 'Nepal',
        'value': 'NP',
      },
      {
        'display_name': 'Nauru',
        'value': 'NR',
      },
      {
        'display_name': 'Niue',
        'value': 'NU',
      },
      {
        'display_name': 'New Zealand',
        'value': 'NZ',
      },
      {
        'display_name': 'Oman',
        'value': 'OM',
      },
      {
        'display_name': 'Panama',
        'value': 'PA',
      },
      {
        'display_name': 'Peru',
        'value': 'PE',
      },
      {
        'display_name': 'French Polynesia',
        'value': 'PF',
      },
      {
        'display_name': 'Papua New Guinea',
        'value': 'PG',
      },
      {
        'display_name': 'Philippines',
        'value': 'PH',
      },
      {
        'display_name': 'Pakistan',
        'value': 'PK',
      },
      {
        'display_name': 'Poland',
        'value': 'PL',
      },
      {
        'display_name': 'Saint Pierre and Miquelon',
        'value': 'PM',
      },
      {
        'display_name': 'Pitcairn',
        'value': 'PN',
      },
      {
        'display_name': 'Palestine, State of',
        'value': 'PS',
      },
      {
        'display_name': 'Portugal',
        'value': 'PT',
      },
      {
        'display_name': 'Palau',
        'value': 'PW',
      },
      {
        'display_name': 'Paraguay',
        'value': 'PY',
      },
      {
        'display_name': 'Qatar',
        'value': 'QA',
      },
      {
        'display_name': 'Réunion',
        'value': 'RE',
      },
      {
        'display_name': 'Romania',
        'value': 'RO',
      },
      {
        'display_name': 'Serbia',
        'value': 'RS',
      },
      {
        'display_name': 'Russian Federation',
        'value': 'RU',
      },
      {
        'display_name': 'Rwanda',
        'value': 'RW',
      },
      {
        'display_name': 'Saudi Arabia',
        'value': 'SA',
      },
      {
        'display_name': 'Solomon Islands',
        'value': 'SB',
      },
      {
        'display_name': 'Seychelles',
        'value': 'SC',
      },
      {
        'display_name': 'Sudan',
        'value': 'SD',
      },
      {
        'display_name': 'Sweden',
        'value': 'SE',
      },
      {
        'display_name': 'Singapore',
        'value': 'SG',
      },
      {
        'display_name': 'Saint Helena, Ascension and Tristan da Cunha',
        'value': 'SH',
      },
      {
        'display_name': 'Slovenia',
        'value': 'SI',
      },
      {
        'display_name': 'Svalbard and Jan Mayen',
        'value': 'SJ',
      },
      {
        'display_name': 'Slovakia',
        'value': 'SK',
      },
      {
        'display_name': 'Sierra Leone',
        'value': 'SL',
      },
      {
        'display_name': 'San Marino',
        'value': 'SM',
      },
      {
        'display_name': 'Senegal',
        'value': 'SN',
      },
      {
        'display_name': 'Somalia',
        'value': 'SO',
      },
      {
        'display_name': 'Suriname',
        'value': 'SR',
      },
      {
        'display_name': 'South Sudan',
        'value': 'SS',
      },
      {
        'display_name': 'Sao Tome and Principe',
        'value': 'ST',
      },
      {
        'display_name': 'El Salvador',
        'value': 'SV',
      },
      {
        'display_name': 'Sint Maarten (Dutch part)',
        'value': 'SX',
      },
      {
        'display_name': 'Syrian Arab Republic',
        'value': 'SY',
      },
      {
        'display_name': 'Swaziland',
        'value': 'SZ',
      },
      {
        'display_name': 'Turks and Caicos Islands',
        'value': 'TC',
      },
      {
        'display_name': 'Chad',
        'value': 'TD',
      },
      {
        'display_name': 'French Southern Territories',
        'value': 'TF',
      },
      {
        'display_name': 'Togo',
        'value': 'TG',
      },
      {
        'display_name': 'Thailand',
        'value': 'TH',
      },
      {
        'display_name': 'Tajikistan',
        'value': 'TJ',
      },
      {
        'display_name': 'Tokelau',
        'value': 'TK',
      },
      {
        'display_name': 'Timor-Leste',
        'value': 'TL',
      },
      {
        'display_name': 'Turkmenistan',
        'value': 'TM',
      },
      {
        'display_name': 'Tunisia',
        'value': 'TN',
      },
      {
        'display_name': 'Tonga',
        'value': 'TO',
      },
      {
        'display_name': 'Turkey',
        'value': 'TR',
      },
      {
        'display_name': 'Trinidad and Tobago',
        'value': 'TT',
      },
      {
        'display_name': 'Tuvalu',
        'value': 'TV',
      },
      {
        'display_name': 'Taiwan',
        'value': 'TW',
      },
      {
        'display_name': 'Tanzania, United Republic of',
        'value': 'TZ',
      },
      {
        'display_name': 'Ukraine',
        'value': 'UA',
      },
      {
        'display_name': 'Uganda',
        'value': 'UG',
      },
      {
        'display_name': 'United States Minor Outlying Islands',
        'value': 'UM',
      },
      {
        'display_name': 'United States',
        'value': 'US',
      },
      {
        'display_name': 'Uruguay',
        'value': 'UY',
      },
      {
        'display_name': 'Uzbekistan',
        'value': 'UZ',
      },
      {
        'display_name': 'Holy See (Vatican City State)',
        'value': 'VA',
      },
      {
        'display_name': 'Saint Vincent and the Grenadines',
        'value': 'VC',
      },
      {
        'display_name': 'Venezuela, Bolivarian Republic of',
        'value': 'VE',
      },
      {
        'display_name': 'Virgin Islands, British',
        'value': 'VG',
      },
      {
        'display_name': 'Virgin Islands, U.S.',
        'value': 'VI',
      },
      {
        'display_name': 'Vietnam',
        'value': 'VN',
      },
      {
        'display_name': 'Vanuatu',
        'value': 'VU',
      },
      {
        'display_name': 'Wallis and Futuna',
        'value': 'WF',
      },
      {
        'display_name': 'Samoa',
        'value': 'WS',
      },
      {
        'display_name': 'Yemen',
        'value': 'YE',
      },
      {
        'display_name': 'Mayotte',
        'value': 'YT',
      },
      {
        'display_name': 'South Africa',
        'value': 'ZA',
      },
      {
        'display_name': 'Zambia',
        'value': 'ZM',
      },
      {
        'display_name': 'Zimbabwe',
        'value': 'ZW',
      },
    ],
    'us_states': [
      {
        'display_name': 'Alaska',
        'value': 'AK',
      },
      {
        'display_name': 'Alabama',
        'value': 'AL',
      },
      {
        'display_name': 'Arkansas',
        'value': 'AR',
      },
      {
        'display_name': 'American Samoa',
        'value': 'AS',
      },
      {
        'display_name': 'Arizona',
        'value': 'AZ',
      },
      {
        'display_name': 'California',
        'value': 'CA',
      },
      {
        'display_name': 'Colorado',
        'value': 'CO',
      },
      {
        'display_name': 'Connecticut',
        'value': 'CT',
      },
      {
        'display_name': 'District of Columbia',
        'value': 'DC',
      },
      {
        'display_name': 'Delaware',
        'value': 'DE',
      },
      {
        'display_name': 'Florida',
        'value': 'FL',
      },
      {
        'display_name': 'Georgia',
        'value': 'GA',
      },
      {
        'display_name': 'Guam',
        'value': 'GU',
      },
      {
        'display_name': 'Hawaii',
        'value': 'HI',
      },
      {
        'display_name': 'Iowa',
        'value': 'IA',
      },
      {
        'display_name': 'Idaho',
        'value': 'ID',
      },
      {
        'display_name': 'Illinois',
        'value': 'IL',
      },
      {
        'display_name': 'Indiana',
        'value': 'IN',
      },
      {
        'display_name': 'Kansas',
        'value': 'KS',
      },
      {
        'display_name': 'Kentucky',
        'value': 'KY',
      },
      {
        'display_name': 'Louisiana',
        'value': 'LA',
      },
      {
        'display_name': 'Massachusetts',
        'value': 'MA',
      },
      {
        'display_name': 'Maryland',
        'value': 'MD',
      },
      {
        'display_name': 'Maine',
        'value': 'ME',
      },
      {
        'display_name': 'Michigan',
        'value': 'MI',
      },
      {
        'display_name': 'Minnesota',
        'value': 'MN',
      },
      {
        'display_name': 'Missouri',
        'value': 'MO',
      },
      {
        'display_name': 'Northern Mariana Islands',
        'value': 'MP',
      },
      {
        'display_name': 'Mississippi',
        'value': 'MS',
      },
      {
        'display_name': 'Montana',
        'value': 'MT',
      },
      {
        'display_name': 'North Carolina',
        'value': 'NC',
      },
      {
        'display_name': 'North Dakota',
        'value': 'ND',
      },
      {
        'display_name': 'Nebraska',
        'value': 'NE',
      },
      {
        'display_name': 'New Hampshire',
        'value': 'NH',
      },
      {
        'display_name': 'New Jersey',
        'value': 'NJ',
      },
      {
        'display_name': 'New Mexico',
        'value': 'NM',
      },
      {
        'display_name': 'Nevada',
        'value': 'NV',
      },
      {
        'display_name': 'New York',
        'value': 'NY',
      },
      {
        'display_name': 'Ohio',
        'value': 'OH',
      },
      {
        'display_name': 'Oklahoma',
        'value': 'OK',
      },
      {
        'display_name': 'Oregon',
        'value': 'OR',
      },
      {
        'display_name': 'Pennsylvania',
        'value': 'PA',
      },
      {
        'display_name': 'Puerto Rico',
        'value': 'PR',
      },
      {
        'display_name': 'Rhode Island',
        'value': 'RI',
      },
      {
        'display_name': 'South Carolina',
        'value': 'SC',
      },
      {
        'display_name': 'South Dakota',
        'value': 'SD',
      },
      {
        'display_name': 'Tennessee',
        'value': 'TN',
      },
      {
        'display_name': 'Texas',
        'value': 'TX',
      },
      {
        'display_name': 'United States Minor Outlying Islands',
        'value': 'UM',
      },
      {
        'display_name': 'Utah',
        'value': 'UT',
      },
      {
        'display_name': 'Virginia',
        'value': 'VA',
      },
      {
        'display_name': 'Virgin Islands',
        'value': 'VI',
      },
      {
        'display_name': 'Vermont',
        'value': 'VT',
      },
      {
        'display_name': 'Washington',
        'value': 'WA',
      },
      {
        'display_name': 'Wisconsin',
        'value': 'WI',
      },
      {
        'display_name': 'West Virginia',
        'value': 'WV',
      },
      {
        'display_name': 'Wyoming',
        'value': 'WY',
      },
      {
        'display_name': 'Armed Forces Americas (except Canada)',
        'value': 'AA',
      },
      {
        'display_name': 'Armed Forces Africa, Canada, Europe, Middle East',
        'value': 'AE',
      },
      {
        'display_name': 'Armed Forces Pacific',
        'value': 'AP',
      },
    ],
  },
  'timezones': {
    'usa': [
      {
        'name': 'America/Adak',
        'utc_offset': -36000,
      },
      {
        'name': 'Hawaii',
        'utc_offset': -36000,
      },
      {
        'name': 'Alaska',
        'utc_offset': -32400,
      },
      {
        'name': 'America/Anchorage',
        'utc_offset': -32400,
      },
      {
        'name': 'America/Metlakatla',
        'utc_offset': -32400,
      },
      {
        'name': 'America/Nome',
        'utc_offset': -32400,
      },
      {
        'name': 'America/Sitka',
        'utc_offset': -32400,
      },
      {
        'name': 'America/Yakutat',
        'utc_offset': -32400,
      },
      {
        'name': 'Pacific Time (US & Canada)',
        'utc_offset': -28800,
      },
      {
        'name': 'America/Boise',
        'utc_offset': -25200,
      },
      {
        'name': 'Arizona',
        'utc_offset': -25200,
      },
      {
        'name': 'Mountain Time (US & Canada)',
        'utc_offset': -25200,
      },
      {
        'name': 'America/Indiana/Knox',
        'utc_offset': -21600,
      },
      {
        'name': 'America/Indiana/Tell_City',
        'utc_offset': -21600,
      },
      {
        'name': 'America/Menominee',
        'utc_offset': -21600,
      },
      {
        'name': 'America/North_Dakota/Beulah',
        'utc_offset': -21600,
      },
      {
        'name': 'America/North_Dakota/Center',
        'utc_offset': -21600,
      },
      {
        'name': 'America/North_Dakota/New_Salem',
        'utc_offset': -21600,
      },
      {
        'name': 'Central Time (US & Canada)',
        'utc_offset': -21600,
      },
      {
        'name': 'America/Detroit',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Indiana/Marengo',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Indiana/Petersburg',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Indiana/Vevay',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Indiana/Vincennes',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Indiana/Winamac',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Kentucky/Louisville',
        'utc_offset': -18000,
      },
      {
        'name': 'America/Kentucky/Monticello',
        'utc_offset': -18000,
      },
      {
        'name': 'Eastern Time (US & Canada)',
        'utc_offset': -18000,
      },
      {
        'name': 'Indiana (East)',
        'utc_offset': -18000,
      },
    ],
    'usa_simplified': {
      'daylight_savings': [
        {
          'display_name': 'Atlantic (ADT / -3:00)',
          'value': -3,
        },
        {
          'display_name': 'Eastern (EDT / -4:00)',
          'value': -4,
        },
        {
          'display_name': 'Central (CDT / -5:00)',
          'value': -5,
        },
        {
          'display_name': 'Mountain (MDT / -6:00)',
          'value': -6,
        },
        {
          'display_name': 'Pacific (PDT / -7:00)',
          'value': -7,
        },
        {
          'display_name': 'Alaskan (AKDT / -8:00)',
          'value': -8,
        },
        {
          'display_name': 'Hawaii/Aleutian (HDT / -9:00)',
          'value': -9,
        },
      ],
      'standard': [
        {
          'display_name': 'Atlantic (AST / -4:00)',
          'value': -4,
        },
        {
          'display_name': 'Eastern (EST / -5:00)',
          'value': -5,
        },
        {
          'display_name': 'Central (CST / -6:00)',
          'value': -6,
        },
        {
          'display_name': 'Mountain (MST / -7:00)',
          'value': -7,
        },
        {
          'display_name': 'Pacific (PST / -8:00)',
          'value': -8,
        },
        {
          'display_name': 'Alaskan (AKST / -9:00)',
          'value': -9,
        },
        {
          'display_name': 'Hawaii/Aleutian (HST / -10:00)',
          'value': -10,
        },
      ],
    },
    'non_usa': [
      {
        'name': 'American Samoa',
        'utc_offset': -39600,
      },
      {
        'name': 'International Date Line West',
        'utc_offset': -39600,
      },
      {
        'name': 'Midway Island',
        'utc_offset': -39600,
      },
      {
        'name': 'Tijuana',
        'utc_offset': -28800,
      },
      {
        'name': 'Chihuahua',
        'utc_offset': -25200,
      },
      {
        'name': 'Mazatlan',
        'utc_offset': -25200,
      },
      {
        'name': 'Central America',
        'utc_offset': -21600,
      },
      {
        'name': 'Guadalajara',
        'utc_offset': -21600,
      },
      {
        'name': 'Mexico City',
        'utc_offset': -21600,
      },
      {
        'name': 'Monterrey',
        'utc_offset': -21600,
      },
      {
        'name': 'Saskatchewan',
        'utc_offset': -21600,
      },
      {
        'name': 'Bogota',
        'utc_offset': -18000,
      },
      {
        'name': 'Lima',
        'utc_offset': -18000,
      },
      {
        'name': 'Quito',
        'utc_offset': -18000,
      },
      {
        'name': 'Atlantic Time (Canada)',
        'utc_offset': -14400,
      },
      {
        'name': 'Caracas',
        'utc_offset': -14400,
      },
      {
        'name': 'Georgetown',
        'utc_offset': -14400,
      },
      {
        'name': 'La Paz',
        'utc_offset': -14400,
      },
      {
        'name': 'Santiago',
        'utc_offset': -14400,
      },
      {
        'name': 'Newfoundland',
        'utc_offset': -12600,
      },
      {
        'name': 'Brasilia',
        'utc_offset': -10800,
      },
      {
        'name': 'Buenos Aires',
        'utc_offset': -10800,
      },
      {
        'name': 'Greenland',
        'utc_offset': -10800,
      },
      {
        'name': 'Montevideo',
        'utc_offset': -10800,
      },
      {
        'name': 'Mid-Atlantic',
        'utc_offset': -7200,
      },
      {
        'name': 'Azores',
        'utc_offset': -3600,
      },
      {
        'name': 'Cape Verde Is.',
        'utc_offset': -3600,
      },
      {
        'name': 'Casablanca',
        'utc_offset': 0,
      },
      {
        'name': 'Dublin',
        'utc_offset': 0,
      },
      {
        'name': 'Edinburgh',
        'utc_offset': 0,
      },
      {
        'name': 'Lisbon',
        'utc_offset': 0,
      },
      {
        'name': 'London',
        'utc_offset': 0,
      },
      {
        'name': 'Monrovia',
        'utc_offset': 0,
      },
      {
        'name': 'UTC',
        'utc_offset': 0,
      },
      {
        'name': 'Amsterdam',
        'utc_offset': 3600,
      },
      {
        'name': 'Belgrade',
        'utc_offset': 3600,
      },
      {
        'name': 'Berlin',
        'utc_offset': 3600,
      },
      {
        'name': 'Bern',
        'utc_offset': 3600,
      },
      {
        'name': 'Bratislava',
        'utc_offset': 3600,
      },
      {
        'name': 'Brussels',
        'utc_offset': 3600,
      },
      {
        'name': 'Budapest',
        'utc_offset': 3600,
      },
      {
        'name': 'Copenhagen',
        'utc_offset': 3600,
      },
      {
        'name': 'Ljubljana',
        'utc_offset': 3600,
      },
      {
        'name': 'Madrid',
        'utc_offset': 3600,
      },
      {
        'name': 'Paris',
        'utc_offset': 3600,
      },
      {
        'name': 'Prague',
        'utc_offset': 3600,
      },
      {
        'name': 'Rome',
        'utc_offset': 3600,
      },
      {
        'name': 'Sarajevo',
        'utc_offset': 3600,
      },
      {
        'name': 'Skopje',
        'utc_offset': 3600,
      },
      {
        'name': 'Stockholm',
        'utc_offset': 3600,
      },
      {
        'name': 'Vienna',
        'utc_offset': 3600,
      },
      {
        'name': 'Warsaw',
        'utc_offset': 3600,
      },
      {
        'name': 'West Central Africa',
        'utc_offset': 3600,
      },
      {
        'name': 'Zagreb',
        'utc_offset': 3600,
      },
      {
        'name': 'Zurich',
        'utc_offset': 3600,
      },
      {
        'name': 'Athens',
        'utc_offset': 7200,
      },
      {
        'name': 'Bucharest',
        'utc_offset': 7200,
      },
      {
        'name': 'Cairo',
        'utc_offset': 7200,
      },
      {
        'name': 'Harare',
        'utc_offset': 7200,
      },
      {
        'name': 'Helsinki',
        'utc_offset': 7200,
      },
      {
        'name': 'Jerusalem',
        'utc_offset': 7200,
      },
      {
        'name': 'Kaliningrad',
        'utc_offset': 7200,
      },
      {
        'name': 'Kyiv',
        'utc_offset': 7200,
      },
      {
        'name': 'Pretoria',
        'utc_offset': 7200,
      },
      {
        'name': 'Riga',
        'utc_offset': 7200,
      },
      {
        'name': 'Sofia',
        'utc_offset': 7200,
      },
      {
        'name': 'Tallinn',
        'utc_offset': 7200,
      },
      {
        'name': 'Vilnius',
        'utc_offset': 7200,
      },
      {
        'name': 'Baghdad',
        'utc_offset': 10800,
      },
      {
        'name': 'Istanbul',
        'utc_offset': 10800,
      },
      {
        'name': 'Kuwait',
        'utc_offset': 10800,
      },
      {
        'name': 'Minsk',
        'utc_offset': 10800,
      },
      {
        'name': 'Moscow',
        'utc_offset': 10800,
      },
      {
        'name': 'Nairobi',
        'utc_offset': 10800,
      },
      {
        'name': 'Riyadh',
        'utc_offset': 10800,
      },
      {
        'name': 'St. Petersburg',
        'utc_offset': 10800,
      },
      {
        'name': 'Volgograd',
        'utc_offset': 10800,
      },
      {
        'name': 'Tehran',
        'utc_offset': 12600,
      },
      {
        'name': 'Abu Dhabi',
        'utc_offset': 14400,
      },
      {
        'name': 'Baku',
        'utc_offset': 14400,
      },
      {
        'name': 'Muscat',
        'utc_offset': 14400,
      },
      {
        'name': 'Samara',
        'utc_offset': 14400,
      },
      {
        'name': 'Tbilisi',
        'utc_offset': 14400,
      },
      {
        'name': 'Yerevan',
        'utc_offset': 14400,
      },
      {
        'name': 'Kabul',
        'utc_offset': 16200,
      },
      {
        'name': 'Ekaterinburg',
        'utc_offset': 18000,
      },
      {
        'name': 'Islamabad',
        'utc_offset': 18000,
      },
      {
        'name': 'Karachi',
        'utc_offset': 18000,
      },
      {
        'name': 'Tashkent',
        'utc_offset': 18000,
      },
      {
        'name': 'Chennai',
        'utc_offset': 19800,
      },
      {
        'name': 'Kolkata',
        'utc_offset': 19800,
      },
      {
        'name': 'Mumbai',
        'utc_offset': 19800,
      },
      {
        'name': 'New Delhi',
        'utc_offset': 19800,
      },
      {
        'name': 'Sri Jayawardenepura',
        'utc_offset': 19800,
      },
      {
        'name': 'Kathmandu',
        'utc_offset': 20700,
      },
      {
        'name': 'Almaty',
        'utc_offset': 21600,
      },
      {
        'name': 'Astana',
        'utc_offset': 21600,
      },
      {
        'name': 'Dhaka',
        'utc_offset': 21600,
      },
      {
        'name': 'Urumqi',
        'utc_offset': 21600,
      },
      {
        'name': 'Rangoon',
        'utc_offset': 23400,
      },
      {
        'name': 'Bangkok',
        'utc_offset': 25200,
      },
      {
        'name': 'Hanoi',
        'utc_offset': 25200,
      },
      {
        'name': 'Jakarta',
        'utc_offset': 25200,
      },
      {
        'name': 'Krasnoyarsk',
        'utc_offset': 25200,
      },
      {
        'name': 'Novosibirsk',
        'utc_offset': 25200,
      },
      {
        'name': 'Beijing',
        'utc_offset': 28800,
      },
      {
        'name': 'Chongqing',
        'utc_offset': 28800,
      },
      {
        'name': 'Hong Kong',
        'utc_offset': 28800,
      },
      {
        'name': 'Irkutsk',
        'utc_offset': 28800,
      },
      {
        'name': 'Kuala Lumpur',
        'utc_offset': 28800,
      },
      {
        'name': 'Perth',
        'utc_offset': 28800,
      },
      {
        'name': 'Singapore',
        'utc_offset': 28800,
      },
      {
        'name': 'Taipei',
        'utc_offset': 28800,
      },
      {
        'name': 'Ulaanbaatar',
        'utc_offset': 28800,
      },
      {
        'name': 'Osaka',
        'utc_offset': 32400,
      },
      {
        'name': 'Sapporo',
        'utc_offset': 32400,
      },
      {
        'name': 'Seoul',
        'utc_offset': 32400,
      },
      {
        'name': 'Tokyo',
        'utc_offset': 32400,
      },
      {
        'name': 'Yakutsk',
        'utc_offset': 32400,
      },
      {
        'name': 'Adelaide',
        'utc_offset': 34200,
      },
      {
        'name': 'Darwin',
        'utc_offset': 34200,
      },
      {
        'name': 'Brisbane',
        'utc_offset': 36000,
      },
      {
        'name': 'Canberra',
        'utc_offset': 36000,
      },
      {
        'name': 'Guam',
        'utc_offset': 36000,
      },
      {
        'name': 'Hobart',
        'utc_offset': 36000,
      },
      {
        'name': 'Melbourne',
        'utc_offset': 36000,
      },
      {
        'name': 'Port Moresby',
        'utc_offset': 36000,
      },
      {
        'name': 'Sydney',
        'utc_offset': 36000,
      },
      {
        'name': 'Vladivostok',
        'utc_offset': 36000,
      },
      {
        'name': 'Magadan',
        'utc_offset': 39600,
      },
      {
        'name': 'New Caledonia',
        'utc_offset': 39600,
      },
      {
        'name': 'Solomon Is.',
        'utc_offset': 39600,
      },
      {
        'name': 'Srednekolymsk',
        'utc_offset': 39600,
      },
      {
        'name': 'Auckland',
        'utc_offset': 43200,
      },
      {
        'name': 'Fiji',
        'utc_offset': 43200,
      },
      {
        'name': 'Kamchatka',
        'utc_offset': 43200,
      },
      {
        'name': 'Marshall Is.',
        'utc_offset': 43200,
      },
      {
        'name': 'Wellington',
        'utc_offset': 43200,
      },
      {
        'name': 'Chatham Is.',
        'utc_offset': 45900,
      },
      {
        'name': "Nuku'alofa",
        'utc_offset': 46800,
      },
      {
        'name': 'Samoa',
        'utc_offset': 46800,
      },
      {
        'name': 'Tokelau Is.',
        'utc_offset': 46800,
      },
    ],
  },
};
