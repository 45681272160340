import mapFontsToLanguages from '../utils/mapFontsToLanguages';
import FONT_ENUMS from './fontEnums';
import { enums } from '@unite-us/client-utils';
import { filter } from 'lodash';

const LANGUAGE_ENUMS = filter(enums?.languageEnums?.languages, function(lang) {
    return (lang.consent === true && lang.shares === true) ||
    (lang.consent === true && (lang.value === "zh" || lang.value === "km") || (lang.shares === true && lang.value === "ps"));
});

const LANGUAGES = {
    supportedList: mapFontsToLanguages(LANGUAGE_ENUMS, FONT_ENUMS),
};

export default LANGUAGES;
