import axios from 'axios';
import { CORE_API } from '../env.config.js';

const fetchConsentAgreement = async (coreTokenObject, language) => {
  const { token } = coreTokenObject;
  const baseURL = CORE_API;
  const coreConfig = {
    params: {
      'filter[state]': 'active',
      'filter[language]': language,
    },
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Application-Source': 'widgets',
    },
  };

  const response = await axios.get('consent_agreements', coreConfig);
  const consentAgreement = response.data.data[0]

  return consentAgreement;
};

export default fetchConsentAgreement;
