import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import enums from '../../constants/defaultEnums';
import { injectIntl, FormattedMessage } from 'react-intl';
import formUtils from '../../utils/formUtils';
import moment from 'moment';

import {
  CustomSelectField,
  CustomInputField,
  CustomDateField,
  CustomLocationAddressField
} from '../../components';

function PersonalForm({ form, assistanceReqConfigs, intl }) {
  const { cl787SexualityFields } = useFlags();
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const MIN_DATE = moment("01/01/1900", "MM/DD/YYYY").utc();
  const collected_attributes = _.get(assistanceReqConfigs, 'data.data[0].attributes.collected_attributes');
  const language = _.get(assistanceReqConfigs, 'language');

  const sexuality = form.getFieldState('sexuality');
  const sexualOrientationsSelected = _.get(sexuality, 'value', []);
  const isSexualOrientationOtherSelected = _.includes(sexualOrientationsSelected, 'other');

  return (
    collected_attributes ? (
      <div>
        <h3 className='mb-2 personal ui-h3-heavy'>
          <FormattedMessage
            id="HZaC7L"
            description="personalInformation"
            defaultMessage="Personal Information"
          />
        </h3>
        <CustomInputField
          name="first_name"
          label={<FormattedMessage
                    id="baGCLv"
                    description="firstName"
                    defaultMessage="First Name"
                  />}
          id="uu_contact_first_name"
          required
          language={language}
        />
        <CustomInputField
          name="last_name"
          label={<FormattedMessage
                  id="2cVC+q"
                  description="lastName"
                  defaultMessage="Last Name"
                />}
          id="uu_contact_last_name"
          required
          language={language}
        />
        { collected_attributes.middle_name_initial && (
        <CustomInputField
          name="middle_name_initial"
          label={<FormattedMessage
                  id="XR18Tw"
                  description="middleNameInitiial"
                  defaultMessage="Middle name initial"
                />}
          id="uu_contact_middle_name"
          language={language}
        />
        )}
        { collected_attributes.title && (
        <CustomSelectField
          name="title"
          label={<FormattedMessage
                  id="tlM/CR"
                  description="Title"
                  defaultMessage="Title"
                />}
          id="uu_contact_title"
          options={formUtils.translateOptions(intl, enums.people.titles)}
          language={language}
        />
        )}
        { collected_attributes.suffix && (
        <CustomSelectField
          name="suffix"
          label={<FormattedMessage
                  id="1PuoMj"
                  description="suffix"
                  defaultMessage="Suffix"
                />}
          id="uu_contact_suffix"
          options={formUtils.translateOptions(intl, enums.people.suffix)}
          language={language}
        />
        )}
        <CustomDateField
          name="date_of_birth"
          label={<FormattedMessage
                  id="Hid0zr"
                  description="dateOfBirth"
                  defaultMessage="Date of Birth"
                />}
          id="uu_contact_date_of_birth"
          other={{
            maxDate: yesterdayDate,
            minDate: MIN_DATE,
          }}
          required
          language={language}
        />
        { collected_attributes.phone_number && (
        <CustomInputField
          name="phone_number"
          label={<FormattedMessage
                  id="9cZViq"
                  description="phoneNumber"
                  defaultMessage=" Phone number"
                />}
          id="uu_contact_phone_number"
          mask="(999)-999-9999"
          required
          language={language}
        />
        )}
        { collected_attributes.email_address && (
        <CustomInputField
          name="email_address"
          label={<FormattedMessage
                  id="u2ISfJ"
                  description="emailAddress"
                  defaultMessage="Email Address"
                />}
          id="uu_contact_email_address"
          required
          language={language}
        />
        )}
        { collected_attributes.address && (
          <div>
            <CustomSelectField
              name="address_type"
              id="uu_contact_address_type"
              label={<FormattedMessage
                      id="4ueNhi"
                      description="addressType"
                      defaultMessage="Address Type"
                    />}
              options={formUtils.translateOptions(intl, enums.contact_data.address_types)}
              required
              language={language}
            />
            <CustomLocationAddressField
              name="address_line_1"
              id="uu-address-typeahead"
              label={intl.formatMessage({
                id: 'VNxktJ',
                description: 'addressLine1',
                defaultMessage: 'Address Line 1',
              })}
              language={language}
              required
            />
            <CustomInputField
              name="line_2"
              id="uu_contact_line_2"
              label={<FormattedMessage
                      id="+/mcIK"
                      description="addressLine2"
                      defaultMessage="Address Line 2"
                    />}
            />
          </div>
        )}
        { collected_attributes.citizenship && (
        <CustomSelectField
          name="citizenship"
          id="uu_contact_citizenship"
          label={<FormattedMessage
                  id="TvXTMt"
                  description="citizenship"
                  defaultMessage="Citizenship"
                />}
          options={formUtils.translateOptions(intl, enums.people.citizenship)}
        />
        )}
        { collected_attributes.gender && (
        <CustomSelectField
          name="gender"
          id="uu_contact_gender"
          label={<FormattedMessage
                  id="dNOgiJ"
                  description="gender"
                  defaultMessage="Gender"
                />}
          options={formUtils.translateOptions(intl, enums.people.genders)}
        />
        )}

        { collected_attributes.marital_status && (
        <CustomSelectField
          name="marital_status"
          id="uu_contact_marital_status"
          label={<FormattedMessage
                  id="3ZVR3f"
                  description="maritalStatus"
                  defaultMessage="Marital Status"
                  />}
          options={formUtils.translateOptions(intl, enums.people.marital_status)}
        />
        )}

        { collected_attributes.race && (
        <CustomSelectField
          name="race"
          id="uu_contact_race"
          label={<FormattedMessage
                  id="IY91py"
                  description="race"
                  defaultMessage="Race"
                />}
          options={formUtils.translateOptions(intl, enums.people.race)}
        />
        )}

        { collected_attributes.ethnicity && (
        <CustomSelectField
          name="ethnicity"
          id="uu_contact_ethnicity"
          label={<FormattedMessage
                  id="Q4TpE8"
                  description="ethnicity"
                  defaultMessage="Ethnicity"
                />}
          options={formUtils.translateOptions(intl, enums.people.ethnicity)}
        />
        )}

        { cl787SexualityFields && collected_attributes.sexuality && (
          <CustomSelectField
            name="sexuality"
            id="uu_contact_sexuality"
            label={<FormattedMessage
                    id="7A7r1r"
                    description="sexuality"
                    defaultMessage="Sexual Orientation"
                  />}
            options={formUtils.translateOptions(intl, enums.people.sexual_orientations)}
            multiple
          />
        )}

        {(cl787SexualityFields && collected_attributes.sexuality && isSexualOrientationOtherSelected) && (
        <CustomInputField
          name="sexuality_other"
          id="uu_contact_sexuality_other"
          maxLength={198}
          label={<FormattedMessage
                  id="7DM5pn"
                  description="sexualityOther"
                  defaultMessage="Other (Please Specify)"
                />}
        />
        )}

        { collected_attributes.gross_monthly_income && (
        <CustomInputField
          name="gross_monthly_income"
          type="number"
          id="uu_contact_gross_monthly_income"
          label={<FormattedMessage
                  id="kWjsrO"
                  description="householdGrossMonthlyIncome"
                  defaultMessage="Household Gross Monthly Income"
                />}
          hint={<FormattedMessage
            id="DiXE3A"
            description="useWholeNumbersOnly"
            defaultMessage="Use whole numbers only"
          />}
          language={language}
          isInteger
        />
        )}

      </div>
    ) : ''
  );
}

PersonalForm.propTypes = {
  assistanceReqConfigs: PropTypes.object.isRequired,
};

export default injectIntl(PersonalForm);
