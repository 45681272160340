import { Field } from 'react-final-form';
import { RadioField } from '@unite-us/ui';
import formUtils from '../utils/formUtils';

const CustomRadioField = ({ name, label, id, options, required, ...rest }) => (
  <Field
    name={name}
    validate={formUtils.required(rest.language)}
  >
    {(params, index) => (
      <>
        <RadioField
          label={label}
          id={id}
          options={formUtils.formatOptions(options)}
          {...params}
          required={required}
        />
      </>
    )}
  </Field>
);

export default CustomRadioField;
