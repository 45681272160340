import { Field } from 'react-final-form';
import { InputField } from '@unite-us/ui';
import formUtils from '../utils/formUtils';

const inputFieldStyle = {
  input: {},
  label: {},
  error: {
    color: 'red'
  }
};

const composeValidators = (validators) => (value) => {
  return validators.reduce((error, validator) => error || validator(value), undefined);
};

const CustomInputField = ({ name, label, id, required, autoFocus, isInteger, ...rest }) => (
  <Field
    name={name}
    validate={
      composeValidators([
        required && formUtils.required(rest.language),
        isInteger && formUtils.isIntegerValidator(rest.language)
    ].filter((e) => e))
    }
  >
    {(params, index) => {
      return (
        <InputField
          label={label}
          id={id}
          style={inputFieldStyle}
          {...rest}
          {...params}
          required={required}
          autoFocus={autoFocus}
        />
      )
    }}
  </Field>
);

export default CustomInputField;
