import axios from 'axios';
import { CORE_API } from '../env.config.js';

const fetchServiceTypes = async (coreTokenObject, language) => {
  const { token, provider_id } = coreTokenObject;
  const baseURL = CORE_API;
  const coreConfig = {
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      'X-Application-Source': 'widgets',
    },
  };
  const params = {
    'locale': language,
    'filter[taxonomy]': 'uniteus',
    'filter[programs.provider]': provider_id,
    'page[number]': 1,
    'page[size]': 300,
  };

  const response = await axios.get('services', { ...coreConfig, params });

  const serviceTypes = response.data.data;

  return serviceTypes;
};

export default fetchServiceTypes;
