import React, { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useParams,
  Link,
  useNavigate,
} from 'react-router-dom';
import AssistanceRequest from './AssistanceRequest/AssistanceRequest';
import AssistanceRequestSuccess from './AssistanceRequest/components/AssistanceRequestSuccess';
import { SERVER_URL } from './env.config.js';
import './App.css';
import './index.css';

function Health() {
  return <p>Health Route</p>;
}

const initCoreToken = {
  coreToken: '',
  providerId: ''
};

function Main({ token }) {
  const [userToken, setUserToken] = useState('');
  const [coreTokenData, setCoreTokenData] = useState(initCoreToken);

  const navigate = useNavigate();

  const renderARWithToken = () => {
    navigate(`/assistance-request/${userToken}`, { replace: true });
    setUserToken('');
  };

  const renderARWithCoreToken = () => {
    const { providerId, coreToken } = coreTokenData;
    navigate(`/assistance-request/null/${coreToken}/${providerId}`, { replace: true });
    setCoreTokenData(initCoreToken)
  };

  return (
    <div className="block border border-b-2">
      <div className="m-3">
        <p className="text-lg mb-3">
          Render widget with a legacy token:
        </p>
        <input
          placeholder="Legacy Token"
          className="block border border-2"
          type="text"
          onChange={(e) => setUserToken(e.target.value)}
        />
        <button
          className="rounded font-bold bg-slate-200 p-2 px-12 mt-3"
          onClick={renderARWithToken}
        >
          Render
        </button>
      </div>

      <div className="m-3">
        <p className="text-lg mb-3">Render widget with a core token + provider id:</p>
        <input
          value={coreTokenData.coreToken}
          placeholder="Core Token"
          className="block border border-2 mb-2"
          type="text"
          onChange={(e) => setCoreTokenData({ ...coreTokenData, coreToken: e.target.value }) }
        />
        <input
          value={coreTokenData.providerId}
          placeholder="Provider"
          className="block border border-2"
          type="text"
          onChange={(e) => setCoreTokenData({ ...coreTokenData, providerId: e.target.value }) }
        />
        <button
          className="rounded font-bold bg-slate-200 p-2 px-12 mt-3"
          onClick={renderARWithCoreToken}
        >
          Render
        </button>
      </div>
    </div>
  )
}

function Default() {
  return <p>Default Route</p>;
}

function NotFound() {
  return <p>Not found route</p>;
}

// http://localhost:8281/assistance-request/null/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjEzMnIyMjk4ZmgifQ.eyJpc3MiOm51bGwsInN1YiI6IjRkMGUxOTRmLWJiNjItNGZjNy04MDIyLTA1ZGFlZTkzM2VlYSIsImVtYWlsIjoiZ2xvYmFsLWFyLWFwaS1rZXlAdW5pdGV1cy5jb20iLCJhdWQiOltdLCJleHAiOjM4MDUwNTMwNDAsIm5iZiI6MTY1NzU2OTM5MywiaWF0IjoxNjU3NTY5MzkzLCJqdGkiOiJiZDg1NzBlZjMxMWEyNThjOTFmODNmM2FhMDBkOTA0ODY1NDhkZjZkNzBlNjc1ODgxYiIsInVzZXJfaW50ZXJjb21faG1hYyI6ImNiNGNlYTZlMjJmMDEwYzM1NzkzZjFkM2I5Mzc1ZDhkZjAzODk1YzU0ZTFlNzRmNjY1ZTkzNmJiZGEzNzY1NmEifQ.ZTWrXs2BcSAR3MoQksTM_OnzScjcFLk8-7n4fvPQ-54/
function App({ token }) {
  const params = useParams();
  const hardCodedLegacyToken = "KiTYKSRBaKA16buHE5NKvT6Wpfl-t9cnVvrzS9mx";
  return (
    <BrowserRouter>
      {/* <div className="border border-b-3 block pb-3">
        <div>
          <Main />
        </div>
        <div className="ml-3">
          <h2 className="text-lg mb-3 mt-3">
            Render widget with hard-coded legacy token => { hardCodedLegacyToken }
          </h2>
          <p>
            <Link to={`/assistance-request/${hardCodedLegacyToken}`}>(Web mode) Assistance Request</Link>
          </p>
          <p>
            <Link to={`/assistance-request/${token}`}>(Widget) Assistance Request</Link>
          </p>
        </div>
      </div> */}
      <Routes>
        <Route path="/assistance-request/:token" element={<AssistanceRequest />} />
        <Route path="/assistance-request/:token/:coreToken/:providerId" element={<AssistanceRequest />} />
        <Route path="/assistance-request/:token/:coreToken/:providerId/:languageCode" element={<AssistanceRequest />} />
        <Route path="/assistance-request/:token/success" element={<AssistanceRequestSuccess />} />
        <Route path="health" element={<Health />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
