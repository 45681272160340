import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SignaturePad from './signature';
import { AWS_DOC_URL } from '../../../../src/env.config.js';
import { FormattedMessage } from 'react-intl';


function Signature({ handleSignatureEnd, failedSubmission, language }) {
  const [signature, setSignature] = useState();
  const [consentPdfUrl, setConsentPdfUrl] = useState();

  useEffect(() => {
    const newSignature = new SignaturePad({
      minWidth: 1,
      onEnd: () => {
        handleSignatureEnd(newSignature.toDataURL());
      },
    });
    newSignature.initialize();
    setSignature(newSignature);
    setConsentPdfUrl(getConsentPdfUrl(language));

    // Sometimes the signature pad initializes before the container is displayed.
    setTimeout(() => {
      const evt = document.createEvent('HTMLEvents');
      evt.initEvent('resize', true, false);
      window.dispatchEvent(evt);
    }, 250);
  }, [failedSubmission, language]);

  const clearSignature = () => {
    handleSignatureEnd(null);
    signature.clear();
  };

  const getConsentPdfUrl = (language) => {
    const basePdfName = 'Unite-Us-Consent';
    if (language !== 'en') {
      return `${AWS_DOC_URL}${basePdfName}-${language}.pdf`;
    }
    return `${AWS_DOC_URL}${basePdfName}.pdf`;
  };

  return (
    <>
      <p>
        <FormattedMessage
          id="2GdJuA"
          description="pleaseSignPre"
          defaultMessage="Please sign in the box below to provide your"
        />
        <strong>
          <a href={consentPdfUrl} target="_blank" rel="noopener noreferrer"> <FormattedMessage id="nIQH95" description="consent" defaultMessage="consent"/> </a>
        </strong>
        <FormattedMessage
          id="iamnyA"
          description="pleaseSignPost"
          defaultMessage="to receive services"
        />
      </p>
      <div style={{ position: 'relative' }}>
        <div id="signature-pad" className="h-60">
          <div className="">
            <canvas className="h-48 w-full border" id="signature-canvas" />
          </div>
        </div>
        <div className="reset" style={{ paddingBottom: '40px' }}>
          <a role="button" onClick={clearSignature}>
            <FormattedMessage
              id="VDXRrl"
              description="clearSignature"
              defaultMessage="Clear Signature"
            />
          </a>
        </div>
      </div>
    </>
  );
}

Signature.propTypes = {
  handleSignatureEnd: PropTypes.func.isRequired,
  failedSubmission: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

export default Signature;
