import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import {get, first, isEmpty, isString, set, map} from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import buildResponses from '@unite-us/surveyjs/dist/components/Renderer/buildResponses';
import TrackerContext from '../utils/context';
import eventConstants from '../utils/eventConstants';
import callOrLog from '../utils/callOrLog';
import '@unite-us/ui/dist/main.css';
import '@unite-us/client-utils/dist/main.css';
import './AssistanceRequest.css';
import SurveyJSForm from '../utils/index';
import PersonalForm from './components/PersonalForm';
import MilitaryForm from './components/MilitaryForm';
import Consent from './components/Signature/Consent';
import AssistanceRequestForm from './components/AssistanceRequestForm';
import useAssistanceRequest from './useAssistanceRequest';
import { createAssistanceRequest } from '../actions';
import formUtils from '../utils/formUtils';
import { CORE_API} from '../env.config.js';
import LANGUAGES from '../utils/languageEnums';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';  
import { FormattedMessage } from 'react-intl';
import { LanguageSelector } from '@unite-us/client-utils';
import { find } from 'lodash';


function AssistanceRequest() {
  const { token: legacyToken, coreToken, providerId, languageCode } = useParams();

  const currentLanguage = sessionStorage.getItem("language");
  const unsupportedLanguage = sessionStorage.getItem("unsupportedLanguage");
  const [state, setState] = useState({
    signature_image: null,
    failedSubmission: false,
  });

  const [hasErrors, setHasErrors] = useState(false);

  useEffect(() => setHasErrors(surveyRef.current?.hasErrors()), [hasErrors]);

  useEffect(() => {
    if (languageCode !== undefined && languageCode !== currentLanguage) {
      handleLocaleChange(languageCode);
    }
  }, [languageCode]);

  let initialCoreTokenData = null;
  if (coreToken && providerId) {
    initialCoreTokenData = {
      api_key: null,
      provider_id: providerId,
      token: coreToken,
    };
  }

  const { signature_image } = state;
  const data = useAssistanceRequest(legacyToken, initialCoreTokenData, currentLanguage);
  const navigate = useNavigate();
  const surveyRef = useRef();
  const eventTracker = useContext(TrackerContext);

  const ldClient = useLDClient();
  const dataProviderId = get(data, 'state.coreTokenData.provider_id', null);

  useEffect(() => {
    if (dataProviderId) {
      ldClient.identify({
        key: 'unknown',
        anonymous: true,
        custom: { providerId: dataProviderId }
      });
    }
  }, [ldClient, dataProviderId]);

  const { showFlTranslations, arSearchAndMatch } = useFlags();
  const showLanguageSelector = showFlTranslations;

  const setBase64SignatureImage = (newSignature) => {
    setState({ ...state, signature_image: newSignature });
  };

  const goToFirstError = () => {
    setHasErrors(surveyRef.current?.hasErrors())
    const errs = [...document.querySelectorAll('.ui-form-field__error')]
      .filter((a) => a.textContent.includes('Required'));
    if (errs && errs.length > 0) {
      const err = first(errs);
      err.parentElement.parentNode.scrollIntoView();
    }
  };

  const handleSetSurvey = (survey) => {
    surveyRef.current = survey;
  };

  const onSubmit = async (response) => {
    const customForm = get(data, 'state.customForm.data.data', null);
    const { Rollbar } = window;
    const coreToken = get(data, 'state.coreTokenData', null);
    const ref = surveyRef;
    const customFormResponses = ref && ref.current ? buildResponses(ref.current) : [];
    const contactFullName = `${get(response, 'first_name')} ${get(response, 'last_name')}`;

    const pdfFile = await formUtils.generatePDFDynamic({
      base64SignatureImage: signature_image,
      fullName: 'name',
      htmlContent: data.state.consentAgreement.attributes.content,
      contactFullName,
    });

    const requestData = {
      assistance_request: {
        description: response.description,
        ...(
          customForm ? {
            form_id: customForm.id,
            form_response: map(customFormResponses, (r) => ({
              name: r.name,
              title: r.title,
              value: r.value,
              displayValue: r.displayValue,
            })),
          } : null
        ),
        requestor: response,
        service_type_id: response.service_types,
      },
      language: currentLanguage,
    };

    if (!surveyRef.current?.hasErrors()) {
      return createAssistanceRequest(coreToken, pdfFile, requestData, arSearchAndMatch).then((r) => {
        const consent_file_upload_url = get(r, 'data.consent_file_upload_url', {});
        if (isEmpty(consent_file_upload_url)) {
          setState({ failedSubmission: true });
          if (Rollbar) { Rollbar.error('Failed to submit the form.', r); }
        } else {
          navigate(`/assistance-request/${data.state.legacyToken}/success`, {
            state: {
              consent: `${CORE_API.split("/v1")[0]}${consent_file_upload_url}`,
              language: currentLanguage,
            },
          });
          setState({ failedSubmission: false });
          callOrLog(() => eventTracker(
            eventConstants.ASSISTANCE_REQUEST.created,
            null,
            { consent_file_upload_url },
          ));
        }
      });
    }
  };

  let assistanceReqConfigs = get(data, 'state.assistanceReqConfigs', null);

  if (assistanceReqConfigs !== null) {
    assistanceReqConfigs.language = currentLanguage;
  } else {
    assistanceReqConfigs = { language: currentLanguage };
  }
  const customForm = get(data, 'state.customForm.data.data', null);
  const serviceTypes = get(data, 'state.serviceTypes', null);

  if (data.isLoading) {
    return <p>Loading..</p>;
  }

  if (data.error) {
    return <p>Error..</p>;
  }

  const unsupportedLanguageFlag = (lc) => {
    const supportedLanguages = LANGUAGES.supportedList;
    const searchedLanguage = find(supportedLanguages, { "value": lc });
    if (searchedLanguage !== undefined) {
      sessionStorage.setItem("unsupportedLanguage", "false");
      return false;
    } else {
      sessionStorage.setItem("unsupportedLanguage", "true");
      return true;
    }
  }

  const handleLocaleChange = (val) => {
    const isUnsupportedLanguage = unsupportedLanguageFlag(val)
    const lc = isUnsupportedLanguage ? "en" : val;
    sessionStorage.setItem("language", lc);
    if (languageCode !== undefined) { // language code on a legacy link is not supported
      navigate(`/assistance-request/${data.state.legacyToken}/${coreToken}/${providerId}/${lc}`);
    }
    window.location.reload();
  }

  return (
    <div className="m-10">
      <Form
        onSubmit={onSubmit}
        initialValues={{}}
        render={({
          handleSubmit, form, submitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {unsupportedLanguage === "true" ? (
              <div className="mt-2 mb-4 font-bold text-red-700">
                It appears you have followed a direct link for a language that we do not currently support.
                The page here is displayed in English. Please use the language selector to translate it to the supported language of your choice.
              </div>
                ) : null}
            {showLanguageSelector && (
              <div className="mb-5">
                <LanguageSelector
                  onChange={handleLocaleChange}
                  field={{ value: currentLanguage }}
                  options={LANGUAGES.supportedList}
                />
              </div>
            )}
            <PersonalForm form={form} assistanceReqConfigs={assistanceReqConfigs} />
            <MilitaryForm form={form} assistanceReqConfigs={assistanceReqConfigs} />
            {customForm && (
              <>
                <h3 className='mb-2 supplemental-questions ui-h3-heavy'>
                  <FormattedMessage id="5vpskb" description="supplementalQuestions" defaultMessage="Supplemental Questions"/>
                </h3>
                <SurveyJSForm
                  id="custom-form"
                  form={customForm.attributes}
                  formRef={handleSetSurvey}
                  expanded
                />
              </>
            )}

            <h3 className='mb-2 services ui-h3-heavy'>
              <FormattedMessage id="9fnevE" description="services" defaultMessage="Services"/>
            </h3>
            {serviceTypes && <AssistanceRequestForm serviceTypes={serviceTypes} language={currentLanguage} />}
            <div className="form-group">
              <Consent handleSignatureEnd={setBase64SignatureImage} failedSubmission={state.failedSubmission} language={currentLanguage} />
              {!signature_image ? (
                <div
                  className="mt-2 mb-5 text-red-700"
                  style={{ marginTop: '-40px' }}
                >
                  <FormattedMessage id="htKh5B" description="signaturePrompt" defaultMessage="Signature Required"/>
                </div>
              ) : ''}
            </div>
            <div className="flex">
              <div>
                {!submitting ? (
                  <button className="px-8 py-2 mb-4 mr-8 font-bold text-white bg-blue-900" type="submit" onClick={() => goToFirstError()}>
                    <FormattedMessage id="AmnqMW" description="submit" defaultMessage="SUBMIT"/>
                  </button>
                  ) : <FormattedMessage id="KFYDxP" description="submitting" defaultMessage="Submitting...">
                        {txt => <p>{txt}</p>}
                      </FormattedMessage> }
              </div>
              <div>
                {state.failedSubmission ? (
                  <div
                    className="mt-2 font-bold text-red-700"
                  >
                    <FormattedMessage id="9QH2Up" description="failedSubmission" defaultMessage="The form failed to submit. Please submit again."/>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default AssistanceRequest;
