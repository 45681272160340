import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { injectIntl } from 'react-intl';
import { WrappedLocationAddressField, useLocationAddressFields } from '@unite-us/client-utils';

import formUtils from '../utils/formUtils';

function CustomLocationAddressField({
  id,
  intl,
  label,
  required,
}) {
  const validate = required ? formUtils.required() : null;
  const fields = useLocationAddressFields(useField, { city: { validate } });
  const cityTouched = fields.city.meta.touched;

  const requiredMessage = intl.formatMessage({
    id: 'GJBQfx',
    description: 'required',
    defaultMessage: 'Required',
  });

  const cityRequiredMessage = intl.formatMessage({
    id: 'jQ18R7',
    description: 'cityRequired',
    defaultMessage: 'City is required',
  });

  return (
    <WrappedLocationAddressField
      id={id}
      label={label}
      fieldNamePath={null}
      required={required}
      requiredMessage={requiredMessage}
      cityRequiredMessage={cityRequiredMessage}
      initialTouched={cityTouched}
      {...fields}
    />
  );
}

CustomLocationAddressField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

CustomLocationAddressField.defaultProps = {
  required: false,
};

export default injectIntl(CustomLocationAddressField);